import FloorHeating57 from './floorHeating57.vue'

function floorHeatingGenertor(h,devSecondType,jdlDeviceId){
    const strategies = {
        57:<FloorHeating57 deviceId={jdlDeviceId}/>,
        notfound:() => <div style="color:green;font-size:16px;">功能正在加紧开发敬请稍后...</div>
     }
     if(strategies[devSecondType]){
         return strategies[devSecondType]
     }
     return strategies['notfound']
}

export default floorHeatingGenertor