import InfraredAlarm from './infraredAlarm.vue'
import InfraredAlarm10 from './infraredAlarm10.vue'

function infraredAlarmGenertor(h,devSecondType,jdlDeviceId,deviceInfo){
    const strategies = {
       10:<InfraredAlarm10 deviceInfo={deviceInfo}/>,
       155:<InfraredAlarm deviceInfo={deviceInfo}/>
    }
    if(strategies[devSecondType]){
        return strategies[devSecondType]
    }
    return <InfraredAlarm deviceInfo={deviceInfo}/>
}

export default infraredAlarmGenertor