<template>
  <div>
    <div class="electric-box__state">
      <div v-for="item in stateList" :key="item.key">
        <div
          class="electric-box__button"
          v-if="!item.circular && !item.special"
          @click="clickBtn(item)"
        >
          <div class="top">{{ deviceState[item.key] }}</div>
          <div class="bottom">{{ item.company }}</div>
          <SettingTwo
            v-if="item.setting"
            theme="outline"
            size="16"
            fill="#333"
            strokeLinejoin="bevel"
            strokeLinecap="butt"
          />
        </div>
        <div
          class="electric-box__button"
          v-if="item.special"
          @click="clickBtn(item)"
        >
          <div
            :class="{
              green: deviceState[item.key],
              red: !deviceState[item.key],
            }"
          >
            {{ deviceState[item.key] ? "启用" : "未启用" }}
          </div>
          <div class="bottom">{{ item.company }}</div>
          <SettingTwo
            theme="outline"
            size="16"
            fill="#333"
            strokeLinejoin="bevel"
            strokeLinecap="butt"
          />
        </div>
        <div class="electric-box__circular" v-if="item.circular">
          <div
            :class="{
              green: deviceState[item.key],
              red: !deviceState[item.key],
            }"
          >
            {{ deviceState[item.key] ? "在线" : "离线" }}
          </div>
          <div class="bottom">{{ item.company }}</div>
        </div>
      </div>
    </div>
    <div class="electric-box__fotter">
      <div class="el-upload__tip">
        更新时间:<span style="color: green">{{ time | dateFormat }}</span>
      </div>
      <div class="icon_box">
        <Caution
          @click="clickBtn(settingList[0])"
          theme="multi-color"
          size="28"
          :fill="['#333', '#2F88FF', '#FFF', '#43CCF8']"
          strokeLinejoin="bevel"
          strokeLinecap="butt"
        />
        <Wifi
          @click="clickBtn(settingList[1])"
          theme="multi-color"
          size="28"
          :fill="['#333', '#2F88FF', '#FFF', '#43CCF8']"
          strokeLinejoin="bevel"
          strokeLinecap="butt"
        />
        <Log
          @click="clickBtn(settingList[2])"
          theme="multi-color"
          size="28"
          :fill="['#333', '#2F88FF', '#FFF', '#43CCF8']"
          strokeLinejoin="bevel"
          strokeLinecap="butt"
        />
        <Change
          @click="clickBtn(settingList[3])"
          theme="multi-color"
          size="28"
          :fill="['#333', '#2F88FF', '#FFF', '#43CCF8']"
          strokeLinejoin="bevel"
          strokeLinecap="butt"
        />
      </div>
    </div>
    <ElectricBoxDialog
      :deviceId="deviceId"
      :deviceState="deviceState"
      :width="width"
      :dialogVisible="visible"
      :info="info"
      @electricBoxDialogClose="handleClose"
    />
  </div>
</template>

<script>
import { getBatchState } from "../../api/device";
import { stateList, settingList } from "./common";
import {
  SettingTwo,
  Caution,
  Wifi,
  Log,
  Change,
  ArrowCircleRight,
} from "@icon-park/vue";
import ElectricBoxDialog from "./dialogSetting";

export default {
  props: {
    deviceId: Number,
  },
  components: {
    SettingTwo,
    Caution,
    Wifi,
    Log,
    Change,
    ArrowCircleRight,
    ElectricBoxDialog,
  },
  data() {
    return {
      timer2: null,
      timer1: null,
      stateList,
      settingList,
      width: "400px",
      time: new Date(),
      info: {},
      visible: false,
      isClick: false,
      deviceState: {
        aw: "--", //功率
        aa: "--", //电流
        av: "--", //电压
        at: "--", //温度
        power: "--", //总电量
        mxgg: "--", //限定功率
        mxgl: "--", //限定电流
        ald: "--", //漏电流
        enableCheck: "--", //漏电自检
      },
    };
  },
  methods: {
    handleClose() {
      this.visible = false;
    },
    clickBtn(item) {
      // 还没请求到数据不能点击出现弹窗
      if (!this.isClick) return;
      if (!item.setting) return;
      this.visible = true;
      this.info = item;
      this.width = item.width;
    },
    getData() {
      const that = this;
      this.timer1 = setInterval(() => {
        getBatchState({ ids: this.deviceId })
          .then((res) => {
            this.deviceState = res.data.result[0];
            this.isClick = true;
          })
          .catch((err) => {
            this.isError(err, this);
          });
      }, 2500);
      this.timer2 = setInterval(() => {
        that.time = new Date();
      }, 1000);
    },
    clearTime() {
      clearInterval(this.timer2);
      clearInterval(this.timer1);
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>

<style lang="scss" scoped>
.icon_box {
  width: 200px;
  display: flex;
  justify-content: space-around;
}
.green {
  color: green;
}
.red {
  color: red;
}
.electric-box__state {
  display: flex;
  flex-flow: wrap;
  .electric-box__button {
    background-color: #edf4ff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100px;
    margin: 10px;
    height: 80px;
    border-radius: 8px;
    .top {
      font-size: 18px;
      color: green;
    }
    .bottom {
      color: #b8b9b9;
    }
  }
  .electric-box__circular {
    background-color: #edf4ff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100px;
    margin: 10px;
    height: 80px;
    border-radius: 8px;
  }
}
.electric-box__fotter {
  margin-left: 10px;
  width: 580px;
  display: flex;
  justify-content: space-between;
}
</style>