import Lamp98 from './lamp98.vue'
import Switch104 from './switch104.vue'
import Curtains100 from './curtains100.vue'

function centralizedControlGenertor(h,devSecondType,jdlDeviceId){
    const strategies = {
        // 灯光集控
        97:<Lamp97 deviceId={ jdlDeviceId }/>,
        98:<Lamp98 deviceId={ jdlDeviceId }/>,
        99:<Lamp98 deviceId={ jdlDeviceId }/>,
        100:<Curtains100 deviceId={ jdlDeviceId }/>,
        101:<Curtains100 deviceId={ jdlDeviceId }/>,
        104:<Switch104 deviceId={ jdlDeviceId }/>,
        105:<Switch104 deviceId={ jdlDeviceId }/>,
        notfound:() => <div style="color:green;font-size:16px;">功能正在加紧开发敬请稍后...</div>
    }
    // 容错处理
    if(strategies[devSecondType]){
        return strategies[devSecondType]
    }
    return strategies['notfound']
    
}

export default centralizedControlGenertor
