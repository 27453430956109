
// 授权类型 0门卡 1在线密码 2离线密码
export const authorizeType = [0, 1, 2];

// 58
export const buttonList902 = [{
    text: '添加在线密码',
    dialog: true,
    buttonText: '确认授权',
    key: 1
}, {
    text: '添加在线门卡',
    dialog: true,
    buttonText: '注册',
    key: 2
}, {
    text: '注销在线门卡',
    buttonText: '注销',
    dialog: true,
    key: 3
}, {
    text: '清空在线密码',
    key: 4,
    devState: "注销所有用户密码"
}, {
    text: '添加离线密码',
    dialog: true,
    buttonText: '确认授权',
    width: '550px',
    key: 5
}, {
    text: '添加离线门卡',
    dialog: true,
    width: '550px',
    key: 7,
    buttonText: '确认授权',
}, {
    text: '注销离线授权',
    key: 8,
    devState:'离线密码失效'
}, {
    text: '启用常开模式',
    key: 9,
    devState:'常开模式'
}, {
    text: '启用密码键盘',
    key: 10,
    devState:'启用本地用户密码'
}, {
    text: '禁用密码键盘',
    key: 11,
    devState:'禁用本地用户密码'
}, {
    text: '启用读卡功能',
    key: 12,
    devState:'启用门卡'
}, {
    text: '禁用读卡功能',
    key: 13,
    devState:'禁用门卡'
}, {
    text: '远程一键开锁',
    key: 14,
    devState:"开锁"
}, {
    text: '门锁初始化',
    width:'700px',
    key: 15,
    dialog:true
}, {
    text: '门锁历史记录',
    dialog: true,
    width:'850px',
    key: 16
}, {
    text: '门锁授权记录',
    dialog: true,
    width:'850px',
    key: 17
}]

// 60
export const buttonList6200 = [
    buttonList902[1],
    buttonList902[2],
    buttonList902[10],
    buttonList902[11],
    buttonList902[12],
    {
        text:'清空门卡',
        key:18
    },
    {
        text: '离线密码初始化',
        width:'700px',
        key: 15,
        dialog:true
    },
    buttonList902[15]
]


export const limitOptions = [
    {
        key: "1",
        name: "次数限制",
    },
    {
        key: "2",
        name: "时间限制",
    },
];

export const limitOptions1 = [
    {
        key: 0,
        name: "次数"
    },
    {
        key: 1,
        name: "小时"
    },
    {
        key: 2,
        name: "天数"
    }
]

function timeGenerator(number) {
    let time = []
    for (let i = 0; i < number; i++) {
        if (i >= 10) {
            time.push({
                value: `${i}`,
                label: `${i}`,
                disabled: false
            })
        } else {
            time.push({
                value: `0${i}`,
                label: `0${i}`,
                disabled: false
            })
        }

    }
    return time
}

export const hours = timeGenerator(24)
export const minute = [{
    value: '00',
    label: '00',
    disabled: false
},{
    value: '20',
    label: '20',
    disabled: false
},{
    value: '40',
    label: '40',
    disabled: false
}]