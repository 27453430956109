<template>
  <div>
    <el-button type="primary" @click="clickBtn('开')">开灯</el-button>
    <el-button type="primary" @click="clickBtn('关')">关灯</el-button>
    <el-button type="primary" @click="visible = true">配对</el-button>
    <el-form label-position="right">
      <el-form-item label-width="130px" label="亮度:">
        <el-slider
          @change="changeSlider"
          v-model="form.devBrightness"
          :min="1"
          :step="10"
          :max="99"
          style="width: 80%"
        >
        </el-slider>
      </el-form-item>
      <el-form-item label-width="130px" label="色温:">
        <el-slider
          @change="changeSlider"
          :min="1"
          :max="99"
          v-model="form.devColorTemperature"
          :step="10"
          style="width: 80%"
        >
        </el-slider>
      </el-form-item>
    </el-form>
    <el-dialog append-to-body width="1000px" :visible.sync="visible" title="已配对">
        <PairTable :dataText="'已配对'" v-if="visible" :deviceId="deviceId"/>
    </el-dialog>
  </div>
</template>

<script>
import { sliderMixin } from "../mixin";
import PairTable from '../../components/EquipmentTable/components/pairTable/index.vue'
import { operationDevice } from '../../api/device'
export default {
  props: {
    deviceId: Number,
  },
  mixins: [sliderMixin],
  components:{
      PairTable
  },
  data() {
    return {
      visible:false,
      form: {
        devId: this.deviceId,
        devState: "开",
        devBrightness: 50,
        devColorTemperature: 50,
      },
    };
  },
  methods: {
    clickBtn(state) {
      operationDevice({ devId: this.deviceId, devState: state })
        .then((res) => {
          this.$message({
            type: "success",
            message: "控制成功",
          });
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
  },
};
</script>