<template>
  <div v-loading="loading">
    <el-button
      style="width: 100px"
      v-for="item in buttonList"
      :key="item.key"
      type="primary"
      @click="clickBtn(item)"
      >{{ item.text }}</el-button
    >
    <el-dialog
      v-loading="loading"
      :visible.sync="visible"
      title="下挂设备"
      width="600px"
      height="420px"
      append-to-body
    >
      <el-table
        :data="tableData"
        highlight-current-row
        height="400px"
      >
        <el-table-column
          prop="devSn"
          label="设备SN码"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="devNickname"
          label="设备名称"
          min-width="100"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { buttonList } from "./common";
import { operationDevice,getGatewayHangDevice } from "../../api/device";
export default {
  props: {
    deviceInfo: Object,
  },
  data() {
    return {
      loading:false,
      buttonList,
      visible:false,
      tableData:[]
    };
  },
  methods: {
    clickBtn(info) {
      this.loading = true
      if (info.dialog) {
          this.visible = true
        getGatewayHangDevice(this.deviceInfo.devSn).then(res => {
            this.tableData = res.data.result
            this.loading = false
        }).catch(err => {
            this.loading = false
            this.isError(err,this)
        })
        return;
      }
      operationDevice({ devId: this.deviceInfo.jdlDeviceId, devState: info.text })
        .then((res) => {
          this.loading = false
          this.$message({
            type: "success",
            message: "控制成功",
          });
        })
        .catch((err) => {
          this.loading = false
          this.isError(err, this);
        });
    },
  },
};
</script>