import OnlineCardPanel from './onlineCardPanel.vue'

function onlineCardPanelGenertor(h,devSecondType,jdlDeviceId,deviceInfo){
    const strategies = {
        18:<OnlineCardPanel deviceInfo={deviceInfo}/>,
     }
     if(strategies[devSecondType]){
         return strategies[devSecondType]
     }
     return <OnlineCardPanel deviceInfo={deviceInfo}/>
}

export default onlineCardPanelGenertor