<template>
  <div>
    <el-button type="primary" @click="clickBtn('开')">开灯</el-button>
    <el-button type="primary" @click="clickBtn('关')">关灯</el-button>
    <el-button type="primary" @click="pair">配对</el-button>
    <slot></slot>
  </div>
</template>

<script>
import { simpleOperationMixin } from '../mixin'
export default {
  props: {
    deviceId: Number,
  },
  mixins:[simpleOperationMixin],
};
</script>