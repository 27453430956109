import Sensor140 from './sensor140.vue'

function sensorGenertor(h,devSecondType,jdlDeviceId,deviceInfo){
    const strategies = {
        140:<Sensor140 deviceId={ jdlDeviceId } deviceInfo={ deviceInfo }/>,
    }
    if(strategies[devSecondType]){
        return strategies[devSecondType]
    }
    return <Sensor140 deviceId={jdlDeviceId}/>
}

export default sensorGenertor