import ScenePanel from './scenePanel.vue'

function scenePanelGenertor(h,devSecondType,jdlDeviceId,hsId){
    const strategies = {
        17:<ScenePanel deviceId={jdlDeviceId} hsId={ hsId }/>,
     }
     if(strategies[devSecondType]){
         return strategies[devSecondType]
     }
     return <ScenePanel deviceId={jdlDeviceId} hsId={ hsId }/>
}

export default scenePanelGenertor 