import {
  PowerContent,
  NetworkSetting,
  // ElectricCurrent,
  LeakageSelfCheck,
  ChangeContent,
  WarningContent,
  DetailParams,
} from "./content";

export default {
  props: {
    info: Object,
    dialogVisible: Boolean,
    deviceState:Object,
    deviceId:Number
  },
  methods: {
    async handleClose(flag) {
      if(flag === "确定"){
        const res = await this.$refs['contentSetting'].submit(this.deviceId,this.deviceState)
        if(!res){
          return
        }
      }
      this.$emit("electricBoxDialogClose");
    },
    open(){
       this.$nextTick(() => {
         if(this.$refs['contentSetting'].getData){
          this.$refs['contentSetting'].getData(this.deviceState,this.deviceId)
         }
       })
    },
  },
  render(h) {
    const ContentSetting = contentSettingGenerator(this.info.key);
    return (
      <el-dialog
        title={this.info.flag}
        visible={this.dialogVisible}
        width={this.$attrs.width}
        append-to-body
        before-close={this.handleClose}
        on-open={ this.open }
      >
        <ContentSetting ref="contentSetting"/>
        {this.info.buttonText ? (
          <span slot="footer" class="dialog-footer">
            <el-button onClick={this.handleClose}>取 消</el-button>
            <el-button type="primary" onClick={() => {
              this.handleClose('确定')
            }}>
              确定
            </el-button>
          </span>
        ) : null}
      </el-dialog>
    );
  },
};

function contentSettingGenerator(key) {
  const strategies = {
    mxgg: PowerContent,
    mxgl: PowerContent,
    enableCheck: LeakageSelfCheck,
    caution: WarningContent,
    wifi: NetworkSetting,
    log: DetailParams,
    change: ChangeContent
  };
  return strategies[key];
}
