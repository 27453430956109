export const infoLeft = [{
    text:'开关',
    key:'switchingState'
},{
    text:'室温',
    key:'roomTemperature'
},{
    text:'模式',
    key:'mode'
},{
    text:'风速',
    key:'speed'
},{
    text:'扫风',
    key:'airSpeedWind'
}]

export const infoRight = [{
    text:'累计电量',
    key:'electricityConsumption',
},{
    text:'功率',
    key:'power'
},{
    text:'电压',
    key:'voltage'
},{
    text:'电流',
    key:'electricCurrent'
},{
    text:'功率因数',
    key:'powerFactor'
}]

export const central = [{
    text:'开关',
    key:'switchingState'
},{
    text:'室温',
    key:'roomTemperature'
},{
    text:'模式',
    key:'mode'
},{
    text:'风速',
    key:'speed'
}]

export const buttonList = {
    "模式":['自动','制冷','制热','除湿','送风'],
    "风速":['自动','高','中','低'],
    "扫风":['自动','手动']
}