import ElectricBox52 from './electricBox52.vue'

function electricBoxGenertor(h,devSecondType,jdlDeviceId){
    const strategies = {
       52:<ElectricBox52 deviceId={jdlDeviceId}/>,
       notfound:() => <div style="color:green;font-size:16px;">功能正在加紧开发敬请稍后...</div>
    }
    if(strategies[devSecondType]){
        return strategies[devSecondType]
    }
    return strategies['notfound']
}

export default electricBoxGenertor