<template>
    <div>
        <el-button type="primary" @click="clickBtn('开')">开</el-button>
        <el-button type="primary" @click="clickBtn('关')">关</el-button>
        <el-button type="primary" @click="pair">配对</el-button>
    </div>
</template>

<script>
import { simpleOperationMixin } from '../mixin'
export default {
    props:{
        deviceId:Number
    },
    mixins:[simpleOperationMixin]
}
</script>