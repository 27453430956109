<template>
  <div v-loading="loading">
    <div v-show="buttonList.length != 0">
        <el-button
          type="primary"
          @click="clickBtn(item.jsroId)"
          v-for="item in buttonList"
          :key="item.jsroId"
          >{{ item.spdDescribe }}</el-button
        >
    </div>
    <div v-show="buttonList.length == 0" style="color: red; font-size: 18px">
      你还没设置情景面板
    </div>
  </div>
</template>

<script>
import {
  getBatchState,
  getScenarioModeByHsId,
  modeControl,
} from "../../api/device";

export default {
  props: {
    deviceId: Number,
    hsId: Number,
  },
  data() {
    return {
      loading: false,
      buttonList: [],
    };
  },
  methods: {
    clickBtn(id) {
      modeControl(id)
        .then((_) => {
          this.$message.success("成功操作");
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    getData() {
      this.loading = true;
      getScenarioModeByHsId({ hsId: this.hsId })
        .then((res) => {
          this.loading = false;
          this.buttonList = res.data.result;
        })
        .catch((err) => {
          this.loading = false;
          this.isError(err, this);
        });
      getBatchState({ ids: this.deviceId })
        .then((res) => {
          if (!res.data.result[0].online) {
            this.$message({
              type: "warning",
              message: "设备不在线啊",
            });
          }
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
  },
};
</script>