import ClothesHanger from './clothesHanger.vue'

function clothesHangerGenerator(h,devSecondType,jdlDeviceId){
    const strategies = {
       157:<ClothesHanger deviceId={ jdlDeviceId }/>
    }
    if(strategies[devSecondType]){
        return strategies[devSecondType]
    }
    return <ClothesHanger deviceId={ jdlDeviceId }/>
}

export default clothesHangerGenerator