<template>
    <Curtain :deviceId="deviceId">
        <el-button type="primary" @click="pair">配对</el-button>
        <div slot="slider">
            <el-form label-position="right" >
                <el-form-item label-width="130px" label="转速调整:">
                <el-slider @change="changeSlider" v-model="form.devPercentage" :max="99" :min="1" :step="10" style="width: 80%">
                </el-slider>
            </el-form-item>
            </el-form>
        </div>
    </Curtain>
</template>

<script>
import { simpleOperationMixin,sliderMixin } from '../mixin'
export default {
    props:{
        deviceId:Number
    },
    mixins:[simpleOperationMixin,sliderMixin],
    data(){
        return {
            form:{
                devId:this.deviceId,
                devPercentage:50
            }
        }
    }
}
</script>