// 依赖红外报警基础组件
import DoorWarningAlarm from '../infraredAlarm/infraredAlarm.vue'

function doorWarningAlarmGenertor(h,devSecondType,jdlDeviceId,deviceInfo){
    const strategies = {
       157:<DoorWarningAlarm deviceInfo={deviceInfo}/>
    }
    if(strategies[devSecondType]){
        return strategies[devSecondType]
    }
    return <DoorWarningAlarm deviceInfo={deviceInfo}/>
}

export default doorWarningAlarmGenertor