<template>
  <div>
    <AirConditioner :deviceId="deviceId" :info="deviceInfo" @updateDeviceInfo="updateDeviceInfo">
      <div slot="left">
        <div class="info__style" v-for="item in infoLeft" :key="item.key">
          {{ item.text }}:{{ deviceInfo[item.key] }}
        </div>
      </div>
      <div slot="right">
        <div class="info__style" v-for="item in infoRight" :key="item.key">
          {{ item.text }}:{{ deviceInfo[item.key] }}
        </div>
      </div>
    </AirConditioner>
  </div>
</template>

<script>
import AirConditioner from "./airConditioner.vue";
import { infoLeft, infoRight } from "./common";

export default {
  components: {
    AirConditioner,
  },
  props: {
    deviceId: Number,
  },
  data() {
    return {
      infoLeft,
      infoRight,
      deviceInfo:{}
    };
  },
  methods:{
    updateDeviceInfo(info){
      this.deviceInfo = info
    }
  }
};
</script>

<style scoped>
.btn__margin {
  margin: 0 10px 0 10px;
}
.info__style {
  color: green;
  font-size: 14px;
  margin: 20px 0 20px 0;
}
.flex {
  display: flex;
}
</style>