<template>
    <div>
        <el-button type="primary" @click="clickBtn('开')">高速风</el-button>
        <el-button type="primary" @click="clickBtn('暂停')">停</el-button>
        <el-button type="primary" @click="clickBtn('关')">低速风</el-button>
    </div>
</template>

<script>
import { simpleOperationMixin } from '../mixin'
export default {
    props:{
        deviceId:Number
    },
    mixins:[simpleOperationMixin]
}
</script>