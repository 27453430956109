<template>
<!-- 一级类型为31的设备复用了此组件注意修改 -->
  <Imap :deviceId="deviceId">
    <el-form label-position="right" >
        <el-form-item label-width="130px" label="亮度:">
        <el-slider @change="changeSlider" v-model="form.devBrightness" :min="1" :step="10" :max="99" style="width: 80%">
        </el-slider>
      </el-form-item>
      <el-form-item label-width="130px" label="色温:">
        <el-slider
          @change="changeSlider"
          :min="1"
          :max="99"
          v-model="form.devColorTemperature"
          :step="10"
          style="width: 80%"
        >
        </el-slider>
      </el-form-item>
    </el-form>
  </Imap>
</template>

<script>
import Imap from "./lamp.vue";
import { sliderMixin } from '../mixin'
export default {
  props: {
    deviceId: Number,
  },
  components: {
    Imap,
  },
  mixins:[sliderMixin],
  data() {
    return {
      form:{
        devId:this.deviceId,
        devState:'开',
        devBrightness:50,
        devColorTemperature:50
      }
    };
  }
};
</script>