export const buttonList = [
    {
      text: "开关",
      id: 1,
      flag:false,
    },
    {
      text: "时间周期",
      id: 2,
      flag:false,
    },
    {
      text: "报警阙值",
      id: 3,
      flag:false,
    },
    {
      text: "灵敏度等级",
      id: 4,
      flag:false,
    },
  ]