<template>
  <div>
    <el-button type="primary" @click="clickBtn('开')">开灯</el-button>
    <el-button type="primary" @click="clickBtn('关')">关灯</el-button>
    <el-button type="primary" @click="visible = true">配对</el-button>
    <el-dialog
      append-to-body
      width="1000px"
      :visible.sync="visible"
      title="已配对"
    >
      <PairTable :dataText="'已配对'" v-if="visible" :deviceId="deviceId" />
    </el-dialog>
  </div>
</template>
<script>
import PairTable from "../../components/EquipmentTable/components/pairTable/index.vue";
export default {
  props: {
    deviceId: Number,
  },
  data() {
    return {
      visible: false,
    };
  },
  components: {
    PairTable,
  },
  methods: {
    clickBtn(state) {
      operationDevice({ devId: this.deviceId, devState: state })
        .then((res) => {
          this.$message({
            type: "success",
            message: "控制成功",
          });
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
  },
};
</script>