export const buttonList = [{
    text:'外出布防',
    key:1
},{
    text:'在家布防',
    key:2
},{
    text:'撤防',
    key:3
},{
    text:'下挂设备',
    key:4,
    dialog:true
}]