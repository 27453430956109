<template>
  <div>
    <div class="door-lock__btnbox">
      <el-button
        @click="handleClick(item)"
        type="primary"
        style="width: 130px"
        v-for="item in buttonList902"
        :key="item.key"
        >{{ item.text }}</el-button
      >
    </div>
    <DoorLockOperationDialog
      ref="doorLockOperationDialog"
      :dialogVisible="visible"
      :deviceInfo="deviceInfo"
      :width="width"
      @doorLockOperationDialogClose="handleClose"
      :info="info"
    />
  </div>
</template>

<script>
import { buttonList902 } from "./common";
import { operationDevice } from "../../api/device";
import DoorLockOperationDialog from "./dialog/index.jsx";
export default {
  props: {
    deviceId: Number,
    deviceInfo: Object,
  },
  components: {
    DoorLockOperationDialog,
  },
  data() {
    return {
      buttonList902,
      visible: false,
      tag: null,
      info: {},
      width: "450px",
    };
  },
  methods: {
    handleClose() {
      this.visible = false;
    },
    handleClick(item) {
      if (item.dialog) {
        this.visible = true;
        this.info = item;
        this.$refs["doorLockOperationDialog"].initChildrenData();
      }
      if (item.width) {
        this.width = item.width;
      } else {
        this.width = "450px";
      }
      if (item.devState) {
        operationDevice({ devId: this.deviceId, devState: item.devState })
          .then((res) => {
            this.$message({
              message: "成功操作",
              type: "success",
            });
          })
          .catch((err) => {
            this.isError(err, this);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.door-lock__btnbox {
  button:first-child {
    margin-left: 10px;
  }
  button {
    margin-top: 10x;
    margin-bottom: 10px;
  }
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
</style>