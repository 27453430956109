<template>
  <div class="infraredAlarm__btnbox">
    <slot name="button" />
    <div class="btn">
      <el-button type="primary" @click="getSmokeAlertEventsData">上报记录</el-button>
    </div>
    <el-dialog append-to-body :visible.sync="visible" title="上报记录" v-loading="loading">
      <el-table :data="tableData" height="600px">
        <el-table-column
          prop="saDeviceSn"
          label="SN码"
          min-width="100px"
        ></el-table-column>
        <el-table-column
          prop="saDeviceStatus"
          label="设备状态"
          min-width="80px"
        ></el-table-column>
        <el-table-column
          prop="saWarningStatus"
          label="报警状态"
          min-width="80px"
        ></el-table-column>
        <el-table-column
          prop="saReportingTime"
          label="上报时间"
          min-width="80px"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { smokeAlertEvents } from "../../api/device";
export default {
  props: {
    deviceInfo: Object,
  },
  data() {
    return {
      tableData: [],
      visible: false,
      loading: false,
    };
  },
  methods: {
    getSmokeAlertEventsData() {
      this.visible = true
      this.loading = true;
      smokeAlertEvents(this.deviceInfo.devSn)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.result || []
        })
        .catch((err) => {
          this.loading = false;
          this.isError(err, this);
        });
    },
    // getLockRecords()
  },
};
</script>

<style scoped lang="scss">
.infraredAlarm__btnbox {
  button {
    margin-top: 10x;
    margin-bottom: 10px;
  }
  .btn {
    margin-left: 10px;
  }
  display: flex;
}
</style>

<style scoped>
.infraredAlarm__btnbox /deep/ .el-popover__reference {
  margin-left: 10px;
  margin-right: 10px;
}
</style>