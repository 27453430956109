<template>
  <el-dialog
    v-dialogDrag
    v-loading="dialogLoading"
    :title="title"
    :visible.sync="dialogVisible"
    :width="dialogWidth"
    :before-close="handleClose"
  >
    <!-- 上传设计稿 -->
    <el-form label-width="90px" :model="positionInfo" ref="positionInfoForm">
      <el-row>
        <el-col :span="6">
          <el-form-item
            prop="affiliation"
            label="选择区域"
            :rules="[
              { required: true, message: '请选择区域', trigger: 'blur' },
            ]"
          >
            <el-input
              v-model="positionInfo.affiliation"
              @click.native="selectAddressBelong('选择地址')"
              size="small"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            prop="designDraftName"
            label="设计稿名称"
            :rules="[
              { required: true, message: '请输入设计稿名称', trigger: 'blur' },
            ]"
          >
            <el-input
              placeholder="可以这样取xxx楼xxx房间"
              v-model="positionInfo.designDraftName"
              size="small"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-upload
      :before-upload="beforeUpload"
      action="https://upload-z2.qiniup.com"
      :data="uploadData"
      :headers="{
        authorization: uploadData.token,
      }"
      :on-success="uploadSuccess"
      :disabled="
        !Boolean(positionInfo.affiliation && positionInfo.designDraftName)
      "
    >
      <el-button
        size="small"
        type="primary"
        :disabled="
          !Boolean(positionInfo.affiliation && positionInfo.designDraftName)
        "
        >点击上传</el-button
      >
      <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
    </el-upload>
    <!-- 上传设计稿结束 -->
    <!-- 编辑设备 -->
    <div class="content">
      <DesignDraftBox
        :isMove="true"
        ref="designDraftBox"
        :bgStyle="bgStyle"
        :deviceList="deviceList"
        :getSaveFunc="getDeviceInfo"
        @deleteDeviceItem="deleteDeviceItem"
      />
      <!--  -->
      <SelectDeviceRight
        v-if="JSON.stringify(bgStyle) != '{}'"
        ref="selectDeviceRight"
        :hsId="positionInfo.hsId"
        @addDeviceList="addDeviceList"
      />
    </div>
    <!-- 选择区域 -->
    <SelectAddress
      v-if="affiliationDialog"
      :isDialogShow="affiliationDialog"
      :isTitle="affiliationDialogTitle"
      @close="selectRoomDblclick"
    >
    </SelectAddress>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleClose(true)">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import DesignDraftBox from "./DesignDraftBox.vue";
import SelectAddress from "@/components/selectAddress/selectAddress";
import SelectDeviceRight from "./SelectDeviceRight.vue";
import {
  getUploadToken,
  addCurtainInfo,
  updateCurtainInfo
} from "../../../api/visualizationDevice";
import moment from "moment";
export default {
  props: {
    dialogVisible: Boolean,
  },
  components: {
    DesignDraftBox,
    SelectAddress,
    SelectDeviceRight,
  },
  data() {
    return {
      title:'设计区域设备',
      dialogLoading: false,
      bgStyle: {},
      affiliationDialog: false,
      affiliationDialogTitle: "",
      img: new Image(),
      uploadData: {
        token: "",
        key: "",
      },
      positionInfo: {
        affiliation: "",
        eaCocId: "",
        hsId: "",
        designDraftName: "",
      },
      info:{},
      devicelocaltions: "",
      deviceList: [],
      dialogWidth: "1200px",
      isEdit:false
    };
  },
  methods: {
    deleteDeviceItem(item){
      this.deviceList.forEach((e,index) => {
        if(e.jourDeviceId === item.jourDeviceId){
          this.deviceList.splice(index,1)
        }
      })
      console.log(this.deviceList)
      this.$refs["selectDeviceRight"].selectRow(this.deviceList)
    },
    //编辑调用方法 父组件visualizationDevice
    editDesignDraft(editData){
      this.title = "编辑区域设备"
      this.isEdit = true
      console.log(editData);
      console.log(this.isEdit);
      this.info = {
        jctUserId:editData.jctUserId,
        jctId:editData.jctId
      }
      this.positionInfo.hsId = editData.jctRoomId
      this.positionInfo.designDraftName = editData.jctName
      this.deviceList = editData.jctDeviceLocations.map(item => ({...item,jourDeviceId:item.jdlDeviceId}))
      this.positionInfo.affiliation = `${editData.hsAddCommunity} ${editData.hsRoomType} ${editData.hsAddDoorplateno}`
      this.getImgSize(editData.jctUrl)
    },
    selectRoomDblclick(row) {
      if (row) {
        console.log(row);
        if (row.checkList == "房间列表") {
          this.positionInfo.affiliation = row.cocContacts;
          this.positionInfo.eaCocId = row.cocId;
        } else {
          this.positionInfo.affiliation = row.address;
          this.positionInfo.hsId = row.hsId;
        }
        if(this.$refs['selectDeviceRight'] !== undefined){
          this.$refs["selectDeviceRight"].getHsId(this.positionInfo.hsId)
          this.$refs['selectDeviceRight'].getAllSheBei(this.deviceList)
        }
      }
      this.affiliationDialog = false;
    },
    selectAddressBelong(str) {
      //选择地址
      this.affiliationDialogTitle = str;
      this.affiliationDialog = true;
    },
    getDeviceInfo(list) {
      this.devicelocaltions = list;
    },
    uploadSuccess(res) {
      console.log(res);
      const imgUrl = `${location.protocol}//pic-gongkai.fangzhizun.com/${res.key}`;
      this.getImgSize(imgUrl);
    },
    async beforeUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = ["jpg", "png"];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message({
          message: "上传文件只能是 jpg,png",
          type: "error",
        });
        return false;
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message({
          message: "上传文件不能超过5M",
          type: "error",
        });
      }
      this.uploadData.key = `xc/public/${moment().format(
        "YYYY-MM-DD hh:mm:ss"
      )}/${file.name}`;
      const res = await getUploadToken();
      this.uploadData.token = res.data.result;
    },
    getImgSize(imgUrl) {
      this.$set(this.img,'src',imgUrl)
       console.log(this.img);
      this.img.onload = () => {
        this.dialogWidth = `${this.img.width + 400}px`;
        this.bgStyle = {
          background: `url('${imgUrl}')`,
          height: this.img.height + "px",
          width: this.img.width + "px",
        };
        console.log(this.bgStyle);
        // 加载图片之后调用
        this.$nextTick(() => {
          this.$refs["selectDeviceRight"].getHsId(this.positionInfo.hsId)
          this.$refs["selectDeviceRight"].getAllSheBei(this.deviceList);
        });
      };
    },
    handleClose(flag) {
      this.resetForm(["positionInfoForm"], this)
      console.log(this.img);
      if (flag === true && this.img.src) {
        if (this.submitForm(["positionInfoForm"], this)) {
          this.$refs["designDraftBox"].parentSave();
          let data = {
            jctName: this.positionInfo.designDraftName,
            jctUrl: this.img.src,
            jctRoomId: this.positionInfo.hsId,
            devicelocaltions: JSON.stringify(this.devicelocaltions),
          };
          this.dialogLoading = true;
          if(this.isEdit){
            data.jctUserId = this.info.jctUserId
            data.jctId = this.info.jctId
            updateCurtainInfo(data).then(res => {
              this.dialogLoading = false;
              this.$emit("handleAddClose", true);
              this.$message({
                type:'success',
                message:'修改成功'
              })
            }).catch(err => {
              this.dialogLoading = false;
              this.isError(err, this);
            })
          }else{
            addCurtainInfo(data)
            .then((res) => {
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.dialogLoading = false;
              this.$emit("handleAddClose", true);
            })
            .catch((err) => {
              this.dialogLoading = false;
              this.isError(err, this);
            });
          }
          Object.assign(this.$data, this.$options.data());
        }
        return;
      }
       Object.assign(this.$data, this.$options.data());
      this.$emit("handleAddClose");
    },
    addDeviceList(arr) {
      arr.forEach(key => {
        key.isHove = false;
        this.deviceList.forEach((key1,i)=>{
          if(key1.jourDeviceId === key.jourDeviceId){
            key.isHove = true;
          }
        })
      });
      arr.forEach(key=>{
        if(!key.isHove){
          this.deviceList.push(key)
        }
      })
      console.log(this.deviceList)
      this.$refs["selectDeviceRight"].selectRow(this.deviceList)
      // this.deviceList = this.deviceList.filter(val => arr.some(item => item.jourDeviceId === val.jourDeviceId))
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
}
.box {
  position: relative;
  .drag {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: move;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: red;
  }
}
</style>