<template>
  <div>
      <el-button type="primary" @click="clickBtn('开')">通电</el-button>
      <el-button type="primary" @click="clickBtn('关')">断电</el-button>
  </div>
</template>

<script>
import { getBatchState } from '../../api/device'
import { simpleOperationMixin } from '../mixin'
export default {
  props: {
    deviceId: Number,
  },
  mixins:[simpleOperationMixin],
  methods: {
    getData() {
      getBatchState({ ids: this.deviceId })
        .then((res) => {
            console.log(res)
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
  },
};
</script>