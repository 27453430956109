import DoorLock from './doorLock.vue'
import DoorLock60 from './doorLock60.vue'

function doorLockGenertor(h,devSecondType,jdlDeviceId,deviceInfo){
    const strategies = {
       58:<DoorLock deviceId={ jdlDeviceId } deviceInfo={deviceInfo}/>,
       60:<DoorLock60 deviceId={ jdlDeviceId } deviceInfo={deviceInfo}/>,
    }
    if(strategies[devSecondType]){
        return strategies[devSecondType]
    }
    return <DoorLock deviceId={ jdlDeviceId }/>
}

export default doorLockGenertor