<template>
  <div class="select-device__box">
    <div>
      设备：<el-cascader
        size="mini"
        @change="cachange"
        :options="leixingList"
        filterable
        :props="{ checkStrictly: true }"
        clearable
        style="width: 200px"
      ></el-cascader>
      <el-table
        ref="deviceRight__table"
        @selection-change="selectionChange"
        row-key="jourDeviceId"
        v-loading="loading"
        :data="deviceList"
        max-height="400"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          prop="devNickname"
          show-overflow-tooltip
          label="名称"
          min-width="80px"
        ></el-table-column>
      </el-table>
    </div>
    <el-button type="primary" size="mini" @click="addDevice"
      >添加进设计稿</el-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      leixingList: [],
      params: {
        devFirstType: "",
        devSecondType: "",
        hsId: "",
        orderRule: 2, //排序规则
        orderWord: 5,
      },
      deviceList: [],
      selectDeviceList: [],
    };
  },
  mounted() {
    this.getAllLeiXing();
  },
  methods: {
    addDevice() {
      if (this.selectDeviceList.length === 0) {
        this.$message({
          type: "warning",
          message: "你还没选择设备呢",
        });
        return;
      }
      this.$emit("addDeviceList", this.selectDeviceList);
    },
    selectionChange(val) {
      console.log(val);
      this.selectDeviceList = val;
    },
    getHsId(hsId) {
      this.params.hsId = hsId;
    },
    getAllSheBei(selectArr) {
      //获取所有设备
      this.loading = true;
      this.$axios({
        method: "get",
        url: "/common2/devices/listByHsId",
        params: this.params,
      })
        .then((res) => {
          console.log(selectArr);
          this.loading = false;
          this.deviceList = res.data.result;
          this.selectRow(selectArr);
        })
        .catch((err) => {
          console.log(err, 111111);
          this.loading = false;
          this.isError(err, this);
        });
    },
    selectRow(selectArr) {
      if(this.$refs["deviceRight__table"]){
        this.$refs["deviceRight__table"].clearSelection()
      }
      this.deviceList.map((row) => {
        selectArr.map((item) => {
          if (row.jourDeviceId === item.jourDeviceId) {
            // el-table 异步渲染
            this.$nextTick(() => {
              this.$refs["deviceRight__table"].toggleRowSelection(row, true);
            });
          }
        });
      });
    },
    cachange(msg) {
      //设备类型查询
      console.log(msg);
      this.params.devFirstType = msg[0];
      this.params.devSecondType = msg[1];
      this.getAllSheBei(this.deviceList);
    },
    getAllLeiXing() {
      //获取所有设备类型
      this.$axios({
        method: "get",
        url: "/common2/companyDeviceSecondTypes",
      })
        .then((res) => {
          for (let i = 0; i < res.data.result.records.length; i++) {
            this.leixingList[i] = {};
            this.leixingList[i].value = res.data.result.records[i].dftId;
            this.leixingList[i].label = res.data.result.records[i].dftName;
            this.leixingList[i].children = [];
          }

          let leixingArr = []; //一级去重
          let leixingObj = {};
          for (let i = 0; i < this.leixingList.length; i++) {
            if (!leixingObj[this.leixingList[i].label]) {
              leixingArr.push(this.leixingList[i]);
              leixingObj[this.leixingList[i].label] = true;
            }
          }
          this.leixingList = leixingArr;

          for (let i = 0; i < this.leixingList.length; i++) {
            //获取二级数据
            for (let j = 0; j < res.data.result.records.length; j++) {
              if (
                this.leixingList[i].value === res.data.result.records[j].dftId
              ) {
                let leixing = {};
                leixing.value = res.data.result.records[j].dstId;
                leixing.label = res.data.result.records[j].dstName;
                this.leixingList[i].children.push(leixing);
              }
            }
          }
          //二级菜单去重
          for (let i = 0; i < this.leixingList.length; i++) {
            let departmentArr = [];
            let departmentObj = {};
            for (let j = 0; j < this.leixingList[i].children.length; j++) {
              if (!departmentObj[this.leixingList[i].children[j].label]) {
                departmentArr.push(this.leixingList[i].children[j]);
                departmentObj[this.leixingList[i].children[j].label] = true;
              }
            }
            this.leixingList[i].children = departmentArr;
          }
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
  },
};
</script>

<style scoped>
.select-device__box {
  margin-left: 20px;
}
</style>