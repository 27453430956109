<template>
  <el-card class="detector-body">
    <!-- v-if="detectorData[item.key]" -->
    <el-col
      v-for="item in detectorText"
      :key="item.key"
      :span="item.span || 8"
      class="detector-col"
    >
      <div class="detector-text">
        <span>{{ item.text }}</span>
      </div>
      <div class="detector-value">
        <el-tag type="success" v-if="!item.state"
          >{{ detectorData[item.key] }}
        </el-tag>
        <el-popover
          v-else
          placement="bottom"
          :title="item.popoverTitle"
          width="200"
          trigger="click"
          @show="showPopover"
          @hide="hidePopover(item.key)"
        >
          <el-input v-model="changeValue" />
          <el-tag type="success" slot="reference"
            >{{ detectorData[item.key] }}
            <i class="el-icon-arrow-down"></i>
          </el-tag>
        </el-popover>
      </div>
    </el-col>
  </el-card>
</template>

<script>
import { getBatchState, changeDetector } from "../../api/device";
const detectorText = [
  { key: "temperature", text: "温度", state: 0 },
  { key: "airHumidity", text: "空气湿度", state: 1, popoverTitle: "取值范围" },
  { key: "formaldehydeConcent", text: "甲醛浓度", state: 0 },
  {
    key: "thresholdTemp",
    text: "最高温度",
    state: 1,
    popoverTitle: "最高温度",
  },
  { key: "minTemp", text: "最低温度", state: 1, popoverTitle: "最低温度" },
  { key: "concentPM25", text: "PM2.5浓度", state: 0 },
  { key: "airHumidityThreshold", text: "空气湿度阈值", state: 0 },
  {
    key: "concentPM25Threshold",
    text: "PM2.5浓度阈值",
    state: 1,
    popoverTitle: "pm2.5浓度阙值",
    span: 10,
  },
  {
    key: "formaldehydeConcentThreshold",
    text: "甲醛浓度阈值",
    state: 1,
    popoverTitle: "甲醛浓度阙值",
  },
  // {key:'uptime',text: '数据更新时间', state:0,span:10},
  { key: "systemTime", text: "设备系统时间", state: 0, span: 10 },
];

export default {
  props: {
    deviceId: Number,
    deviceInfo: Object,
  },
  data() {
    return {
      detectorText,
      detectorData: {},
      changeValue: "",
      timer:""
    };
  },
  methods: {
    hidePopover() {},
    getData() {
      this.getSensorData();
      this.timer = setInterval(() => {
        this.getSensorData();
      }, 5000);
    },
    getSensorData() {
      console.log("执行");
      getBatchState({ ids: this.deviceId })
        .then((res) => {
          this.detectorData = res.data.result[0];
          if (!res.data.result[0].online) {
            this.$message.warning("设备不在线");
          }
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    clearTime(){
        clearInterval(this.timer)
    },
    showPopover() {
      this.changeValue = "";
    },
    hidePopover(key) {
     if(this.changeValue == '') return
      changeDetector(this.deviceInfo.sn, { [key]: this.changeValue })
        .then(() => {
          this.getSensorData();
          this.$message.success("控制成功，稍后生效");
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.detector-body {
  display: flex;
  // flex-wrap: wrap;
  // justify-content: flex-start;
  padding-right: 30px;
  .detector-col {
    display: flex;
    margin: 20px 0;
  }
}
.detector-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  box-sizing: border-box;
  // margin-left: 30px;
  padding-left: 30px;
}
.detector-value {
  // display: flex;
  // justify-content: flex-start;
  // align-items: center;
  flex: 1;
  // margin-right: 30px;
  span {
    width: 100%;
    text-align: center;
  }
  .el-icon-arrow-down {
    float: right;
    margin-top: 10px;
  }
}
</style>
