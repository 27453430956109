<template>
  <!--  -->
  <div @mousewheel="scaleDom" ref="box" id="visualize_device_box" class="box" :style="bgStyle"> 
    <!-- 可移动 -->
    <div v-if="isMove" :key="1">
      <div
        v-drag
        @contextmenu.prevent="deviceItemClick($event,item)"
        :style="item.jdlXCoordinate ? { left: item.jdlXCoordinate, top: item.jdlYCoordinate } : null"
        ref="deviceItem"
        class="drag"
        :data-id="item.jourDeviceId || item.jdlDeviceId"
        v-for="item in deviceList"
        :key="item.jourDeviceId"
      >
      <el-popover
        placement="bottom" 
        v-model="item.visible"  
        trigger="manual"
      >
      <p>确定删除这个设备吗？</p>
      <div style="text-align: right; margin: 0">
        <el-button size="mini" type="text" @click="item.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="deleteDevice(item)">确定</el-button>
      </div>
        <el-tooltip
        slot="reference"
          class="item"
          effect="dark"
          :content="item.devNickname"
         placement="top"
        >
          <span>
            <img :data-id="item.jourDeviceId" :src="item.dftIcon" alt="" />
          </span>
        </el-tooltip>
        </el-popover>
      </div>
      
    </div>
    <!-- 不可移动 -->
    <div v-if="!isMove" :key="2">
      <div
        :style="{ left: item.jdlXCoordinate, top: item.jdlYCoordinate }"
        @click="clickFunc(item)"
        ref="deviceItem"
        class="drag"
        :data-id="item.jourDeviceId"
        v-for="item in deviceList"
        :key="item.jourDeviceId"
      >
       <el-tooltip
          class="item"
          effect="dark"
          :content="item.devNickname"
          placement="right-end"
        >
          <span>
            <img :src="item.dftIcon" alt="" />
          </span>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { drag } from "./directive";
export default {
  props: {
    isMove: Boolean,
    bgStyle: Object,
    deviceList: Array,
    getSaveFunc: Function,
    clickFunc: Function,
  },
  directives: {
    drag,
  },
  data() {
    return {
      boxStyle: {},
      id:'jourDeviceId',
      clickDeviceItem:'',
      visible:false
    };
  },
  watch: {
        visible(value) {
            if (value) {
                document.body.addEventListener('click', this.closeMenu)
            } else {
                document.body.removeEventListener('click', this.closeMenu)
            }
        }
    },
  methods: {
    closeMenu() {
      this.visible = false
      this.deviceList.forEach(e => {
        e.visible = false
      })
    },
    deviceItemClick(e,item){
      if(e.button === 2){
        item.visible = true
        this.visible = true
        this.clickDeviceItem = item
      }
    },
    deleteDevice(){
      this.$emit('deleteDeviceItem',this.clickDeviceItem)
    },
    parentSave() {
      if(this.deviceList.length === 0){
        this.$message({
          message:"你还没添加设备不能点击保存",
          type:"warning"
        })
        return
      }
      let arr = this.$refs['deviceItem'].map(e => {
        return {
          jdlDeviceId:Number(e.dataset.id),
          jdlXCoordinate:e.style.left,
          jdlYCoordinate:e.style.top
        }
      })
      this.getSaveFunc(arr);
    },
    scaleDom(event) {
      let div = this.$refs["box"];
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  position: relative;
  /* height: 824px;
  width: 1024px;
  background: url("http://pic-gongkai.fangzhizun.com/xc/public/2021-06-10%2010:40:43/%E5%B9%B3%E9%9D%A2%E8%AE%BE%E8%AE%A1%E5%9B%BE.jpg"); */
  .drag {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: move;
    color: white;
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
    img {
      width: 34px;
      height: 34px;
    }
  }
}
</style>