import { SelectUser, TipBox, ParagraphBox, Paragraph1, Paragraph2, Paragraph3, Paragraph4 } from "./components.jsx";
import { validateUserCoding, password } from "./verfiy";
import { operationDevice, getAuthorizationRecord, getLockRecords } from "@/api/device";
import { limitOptions, authorizeType } from "../common";
import { dateLimit } from "./verfiy";
import { dateFtt } from "@/assets/utils";

const rules = {
  jdcUserId: [
    { validator: validateUserCoding, trigger: ["blur", "change"] },
    { required: true, message: "不知道你要给谁授权呀!", trigger: ["blur", "change"] },
  ],
  jdcPassword: password,
};

const submitMixin = {
  methods: {
    async submit(deviceItem) {
      if (!this.submitForm(["form"], this)) return false;
      this.loading = true;
      let data = JSON.parse(JSON.stringify(this.form));
      data.jdcUserId = this.form.jdcUserId[2];
      data.jdcHsId = deviceItem.hsId;
      data.devId = deviceItem.jdlDeviceId;
      try {
        await operationDevice(data);
        this.$message({
          type: "success",
          message: "添加成功",
        });
        Object.assign(this.$data, this.$options.data());
        this.$refs["form"].resetFields();
        return true;
      } catch (error) {
        this.loading = false;
        this.isError(error, this);
        return false;
      }
    },
  },
};

// 添加在线密码
export const ContentOper1 = {
  data() {
    return {
      limitOptions,
      loading: false,
      form: {
        authorizeType: authorizeType[1],
        jdcUserId: "",
        mode: "次数限制",
        jdcPassword: "",
        devState: "添加密码",
        jdcHsId: "",
        devId: "",
        // 时间
        jdcDeadlineTime: "",
        // 次数
        jdcMaxUnlockingTimes: "",
      },
    };
  },
  mixins: [submitMixin],
  methods: {
    limitOptionsChange() {
      (this.form.jdcDeadlineTime = ""), (this.form.jdcMaxUnlockingTimes = "");
    },
  },
  render(h) {
    return (
      <div v-loading={this.loading}>
        <el-form label-width="80px" props={{ model: this.form, rules }} ref="form">
          <el-form-item label="用户" prop="jdcUserId">
            <SelectUser style="width:180px" size="small" vModel={this.form.jdcUserId} />
          </el-form-item>
          <el-form-item label="限制">
            <el-select style="width:180px" onChange={this.limitOptionsChange} size="small" vModel={this.form.mode}>
              {limitOptions.map((item) => (
                <el-option key={item.key} label={item.name} value={item.name} />
              ))}
            </el-select>
          </el-form-item>
          <el-form-item label="密码" prop="jdcPassword">
            <el-input style="width:180px" size="small" vModel={this.form.jdcPassword} />
          </el-form-item>
          {this.form.mode === "次数限制" ? (
            <el-form-item label="开锁次数" prop="jdcDeadlineTime">
              <el-input style="width:180px" size="small" vModel_number={this.form.jdcMaxUnlockingTimes} />
            </el-form-item>
          ) : (
            <el-form-item label="期限时间" prop="jdcMaxUnlockingTimes">
              <el-date-picker
                v-model={this.form.jdcDeadlineTime}
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:00:00"
                size="mini"
                style="width:180px"
                picker-options={dateLimit}
              />
            </el-form-item>
          )}
          <el-form-item>
            <TipBox class="el-upload__tip">
              tip:不输入<span style="color:green;">{this.form.mode === "次数限制" ? "开锁次数" : "期限时间"}</span>
              则为永久
            </TipBox>
          </el-form-item>
        </el-form>
      </div>
    );
  },
};

export const ContentOper2 = {
  render(h) {
    return <div>添加在线门卡</div>;
  },
};

export const ContentOper3 = {
  render(h) {
    return <div>注销在线门卡</div>;
  },
};

export const ContentOper15 = {
  data() {
    return {
      loading: false,
      password: null,
    };
  },
  methods: {
    initData(deviceItem) {
      this.loading = true;
      const data = {
        authorizeType: authorizeType[2],
        encryptMode: 0,
        jdcMaxUnlockingTimes: "999",
        startTime: dateFtt("yyyy-MM-dd hh:00:00", new Date()),
        devId: deviceItem.jdlDeviceId,
        devState: "添加密码",
      };
      operationDevice(data)
        .then((res) => {
          this.loading = false;
          let password = res.data.result;
          let initialize1 = password.slice(0, 3);
          let initialize2 = password.slice(3, 6);
          let initialize3 = password.slice(6, 9);
          this.password = `${initialize1} ${initialize2} ${initialize3}`;
        })
        .catch((err) => {
          this.loading = false;
          this.isError(err, this);
        });
    },
  },
  render(h) {
    return (
      <div v-loading={this.loading}>
        <div>
          <ParagraphBox>
            <Paragraph1>你本次获取门锁初始化密码是</Paragraph1>
            <Paragraph2>{this.password}</Paragraph2>
            <Paragraph3>(每次初始化需要重新生成,非长期固定密码)</Paragraph3>
          </ParagraphBox>
          <Paragraph4 props={{ color: "black" }}>使用帮助:</Paragraph4>
          <Paragraph4 props={{ color: "black" }}>
            1、门锁通电情况下长按“#”键3秒，按键灯常亮，即进入初始化模式；
          </Paragraph4>
          <Paragraph4 props={{ color: "black" }}>2、输入系统生成的初始化密码，按“#”确认。</Paragraph4>
          <Paragraph4 props={{ color: "#999" }}>
            以上步骤操作过程，门锁间隔1秒“滴..滴”响两声代表成功，连续响两声代表失败
          </Paragraph4>
        </div>
      </div>
    );
  },
};

const ContentOperMixin16And17 = {
  methods: {
    changeCurrent(value) {
      this.params.cursor = value;
      this.initData();
    },
    changeSize(value) {
      this.params.limit = value;
      this.initData();
    },
  },
};

export const ContentOper16 = {
  data() {
    return {
      tableData: [],
      sn: null,
      params: {
        cursor: 1,
        limit: 5,
      },
      loading: false,
      total: null,
    };
  },
  mixins: [ContentOperMixin16And17],
  methods: {
    initData(deviceItem) {
      this.loading = true;
      if (deviceItem) {
        this.sn = deviceItem.devSn;
      }
      getLockRecords(this.sn, this.params)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.result.records;
          this.total = res.data.result.total;
        })
        .catch((err) => {
          this.loading = false;
          this.isError(err, this);
        });
    },
  },
  render(h) {
    return (
      <div>
        <el-table data={this.tableData} v-loading={ this.loading } height="403px" highlight-current-row>
            <el-table-column prop="unlockPeople" label="人员" min-width="60px"></el-table-column>
            <el-table-column prop="devNickname" label="设备名称" min-width="60px"></el-table-column>
            <el-table-column prop="devAddress" label="设备安装地址" min-width="100px"></el-table-column>
            <el-table-column prop="jglrRecordType" label="上报信息类型" min-width="70px"></el-table-column>
            <el-table-column prop="jglrTime" label="时间" width="160px"></el-table-column>
        </el-table>
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          total={this.total}
          pageSize={this.params.limit}
          on-current-change={this.changeCurrent}
          on-size-change={this.changeSize}
          page-sizes={[5, 10, 20, 40, 100]}
          background
          align="center"
        ></el-pagination>
      </div>
    );
  },
};

export const ContentOper17 = {
  data() {
    return {
      id: null,
      loading: false,
      params: {
        cursor: 1,
        limit: 5,
      },
      tableData: [],
      total: null,
    };
  },
  mixins: [ContentOperMixin16And17],
  methods: {
    initData(deviceItem) {
      if (deviceItem) {
        this.id = deviceItem.jdlDeviceId;
      }
      this.loading = true;
      getAuthorizationRecord(this.id, this.params)
        .then((res) => {
          this.loading = false;
          this.tableData = [];
          this.total = res.data.result.total;
        })
        .catch((err) => {
          this.loading = false;
          this.isError(err, this);
        });
    },
  },
  render(h) {
    return (
      <div>
        <el-table data={this.tableData} v-loading={this.loading} height="330px" highlight-current-row>
          <el-table-column prop="address" label="授权地址" min-width="100px"></el-table-column>
          <el-table-column prop="jdcAuthorizeType" label="授权类型" min-width="60px"></el-table-column>
          <el-table-column prop="jdcCardNum" label="卡号" min-width="60px"></el-table-column>
          <el-table-column prop="jdcPassword" label="密码" min-width="60px"></el-table-column>
          <el-table-column prop="jdcState" label="状态" min-width="40px"></el-table-column>
          <el-table-column prop="jdcPublishTime" label="授权时间" min-width="100px"></el-table-column>
        </el-table>
        {/* jsx on-current-change on-size-change  */}
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          total={this.total}
          pageSize={this.params.limit}
          on-current-change={this.changeCurrent}
          on-size-change={this.changeSize}
          page-sizes={[5, 10, 20, 40, 100]}
          background
          align="center"
        ></el-pagination>
      </div>
    );
  },
};
