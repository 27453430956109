/*
  嘿我就是不写注释就是玩 别想着我是怎么实现的 先去看README.md文件吧
*/
import deviceOperationGenerator from "./core";
import { getBatchState } from "../api/device";
import { deviceInfoLog } from "./utils";

export default {
  name: "DeviceOperationDialog",
  props: {
    dialogVisible: Boolean,
    deviceInfo:Object
  },
  methods: {
    handleClose(cpn) {
      // 使用虚拟dom实例
      if(cpn.componentInstance && cpn.componentInstance.clearTime){
        cpn.componentInstance.clearTime()
      }
      this.$emit("handleDeviceOperationDialog");
    },
    useComponentMethod(cpn){
      this.$nextTick(() => {
        if(cpn.componentInstance && cpn.componentInstance.getData){
          cpn.componentInstance.getData()
        }else{
          getBatchState({ ids: this.deviceInfo.jdlDeviceId }).then((res) => {
            if (!res.data.result[0].online) {
              this.$message({
                type: "warning",
                message: "设备不在线啊",
              });
            }
          }).catch(err => {
            this.isError(err,this)
          });
        }
      })
    },
  },
  render(h) {
    const component = deviceOperationGenerator(
      h,
      this.deviceInfo.devFirstType,
      this.deviceInfo.devSecondType,
      this.deviceInfo.jdlDeviceId,
      this.deviceInfo
    )();
    deviceInfoLog(this.deviceInfo);
      return (
        <el-dialog
          title="设备操作"
          visible={this.dialogVisible}
          {...{attrs:this.$attrs}}
          width={this.$attrs.width}
          before-close={() => this.handleClose(component)}
          on-open={ () => this.useComponentMethod(component) }
        >
          {component}
        </el-dialog>
      );
  },
};
