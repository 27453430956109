import { mapState } from "vuex";
import styled from 'vue-styled-components'

export const TimeBox = styled.div`
    width:300px;
    color:green;
    text-align:center;
`

export const TipBox = styled.div`
  color: red;
  margin-top: -10px;
`

export const PasswordBox = styled.div`
  width: 180px;
  text-align: center;
  color: green;
  font-size: 18px;
`

export const ParagraphBox = styled.div`
  width: 100%;
  text-align: center;
`

export const Paragraph1 = styled.div`
  font-size: 24px;
`

export const Paragraph2 = styled.div`
  font-size: 30px;
  margin-top: 30px;
  margin-left: 20px;
  color: red;
`

export const Paragraph3 = styled.div`
  font-size: 16px;
  color: #999999;
`

export const Paragraph4 = styled('div',['color'])`
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
  color: ${props => props.color};
`

export const SelectUser = {
  props: {
    value:'',
  },
  computed: {
    ...mapState(["userNameList"]),
  },
  render(h) {
    return (
      <el-cascader
        {...{attrs:this.$attrs}}
        value={this.value}
        options={this.userNameList}
        clearable
        onChange={($event) => this.$emit("input", $event)}
      ></el-cascader>
    );
  },
};