import Curtain from './curtain.vue'
import Curtain117 from './curtain117.vue'
import Curtain108 from './curtain108.vue'

function curtainGenertor(h,devSecondType,jdlDeviceId){
    const strategies = {
        41:<Curtain117 deviceId={ jdlDeviceId }/>,
        // 转型窗帘
        117:<Curtain117 deviceId={ jdlDeviceId }/>,
        // 百分比窗帘
        108:<Curtain108 deviceId={ jdlDeviceId }/>
    }
    if(strategies[devSecondType]){
        return strategies[devSecondType]
    }
    return <Curtain deviceId={ jdlDeviceId }/>
}

export default curtainGenertor