import request from '../utils/request';
const url = (process.env.VUE_APP_TITLE == 'online' || process.env.VUE_APP_TITLE == 'testing') ? '/common1/photos/token' : '/common1/open/photos/token'

export function getUploadToken() {
    return request({
        url: url
    })
}

export function addCurtainInfo(data){
    return request({
        url:'/common2/curtainInfo/addCurtainInfo',
        method:'post',
        data
    })
}

export function getCurtainInfo(params){
    return request({
        url:'/common2/curtainInfo/getCurtainInfo',
        method:'GET',
        params
    })
}

export function updateCurtainInfo(data){
    return request({
        url:'/common2/curtainInfo/updateCurtainInfo',
        method:'PUT',
        data
    })
}

export function deleteCurtainInfo(params){
    return request({
        url:'/common2/curtainInfo/deleteCurtainInfo',
        method:'delete',
        params
    })
}
