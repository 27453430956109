import Lamp31 from './lamp31.vue'
import Lamp from './lamp.vue'

function lampGenerator(h,devSecondType,jdlDeviceId){
    const strategies = {
        31:<Lamp31 deviceId={jdlDeviceId}/>,
        76:<Lamp31 deviceId={jdlDeviceId}/>
    }
    // 容错处理
    if(strategies[devSecondType]){
        return strategies[devSecondType]
    }
    return <Lamp deviceId={jdlDeviceId}/>
}

export default lampGenerator