<template>
  <div class="clothes-hanger__box">
    <div v-for="item in buttonList" :key="item.key">
      <div class="clothes-hanger__button">
        <el-button style="width: 100px" @click="clickBtn(item.name)" v-if="!item.flag" type="primary">{{
          item.name
        }}</el-button>
      </div>
      <div class="clothes-hanger__dropdown">
        <el-dropdown v-if="item.flag" style="margin:0 5px" split-button type="primary" @click="clickDropdown(item.flag,100)" @command="ahight(item.flag,$event)"
          >{{ item.name }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="20">20%</el-dropdown-item>
            <el-dropdown-item command="40">40%</el-dropdown-item>
            <el-dropdown-item command="60">60%</el-dropdown-item>
            <el-dropdown-item command="80">80%</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    
  </div>
</template>

<script>
import { buttonList } from "./common";
import { operationDevice } from '../../api/device'
import { simpleOperationMixin } from '../mixin'

export default {
  props: {
    deviceId: Number,
  },
  data() {
    return {
      buttonList,
    };
  },
  mixins:[simpleOperationMixin],
  methods: {
    ahight(flag,$event){
      const data = {
        devId:this.deviceId,
        devPercentage:$event,
        devState:flag
      }
      operationDevice(data).then(_ => {
        this.$message.success("控制成功")
      }).catch(err => {
        this.isError(err,this)
      })
    },
    clickDropdown(flag,company){
      const data = {
        devId:this.deviceId,
        devPercentage:company,
        devState:flag
      }
      operationDevice(data).then(_ => {
        this.$message.success("控制成功")
      }).catch(err => {
        this.isError(err,this)
      })
    }
  },
};
</script>

<style scoped>
.clothes-hanger__box {
  display: flex;
  flex-wrap: wrap;
}
.clothes-hanger__button >>> .el-button {
  margin-left: 10px;
  margin-bottom: 5px;
}
.clothes-hanger__dropdown{
  margin-left: 5px;
}
</style>