import { SelectUser, TimeBox, TipBox, PasswordBox } from "./components.jsx";
import { limitOptions1, hours, minute,authorizeType } from "../common";
import moment from 'moment'
import { dateFtt } from "@/assets/utils"
import { dateLimit,validateUserCoding,rulesGenerator } from './verfiy'
import { operationDevice } from "@/api/device";

const rules = {
  jdcUserId: [
    { validator: validateUserCoding, trigger: ["blur", "change"] },
    { required: true, message: "不知道你要给谁授权呀!", trigger: ["blur", "change"] },
  ]
};

export default {
    name:'ContentOper5And7',
    props:{
      text:String
    },
    data() {
      return {
        password: null,
        loading:false,
        max:9,
        form: {
          encryptMode:0,
          jdcUserId: "",
          jdcMaxUnlockingTimes: "",
          mode: "次数",
          time1: {
            date: "",
            hour: "",
            minute: "",
          },
        },
        limitOptions1,
        optionsHours:hours,
        optionsMinute:minute
      };
    },
    computed:{
      date(){
        if(!this.form.time1.date && !this.form.time1.hour && !this.form.time1.minute){return ''}
        if(this.form.mode === "天数"){
          let date = new Date(this.form.time1.date).getTime() + (Number(this.form.jdcMaxUnlockingTimes)*86400000)
           return moment(date).format('YYYY-MM-DD');
        }
        let hour = Number(this.form.jdcMaxUnlockingTimes) + Number(this.form.time1.hour)
        let sum = Math.floor(hour / 24)
        let date = new Date(this.form.time1.date).getTime() + (sum*86400000)
        return moment(date).format('YYYY-MM-DD');
      },
      hour(){
        if(this.form.mode === "天数"){
          return this.form.time1.hour
        }
        let sum = Number(this.form.jdcMaxUnlockingTimes) + Number(this.form.time1.hour)
        if(sum/24 >=1){
          return sum % 24
        }
        return sum
      },
      minute(){
        return this.form.time1.minute
      },
    },
    methods:{
      async submit(deviceItem){
          this.loading = true
          if(!this.submitForm(['form'],this)) return false
          let data = {}
          data.sendMessage = 0
          data.devState = "添加密码"
          data.authorizeType = authorizeType[2]
          data.encryptMode = this.form.encryptMode
          data.jdcMaxUnlockingTimes = this.form.jdcMaxUnlockingTimes
          data.jdcUserId = this.form.jdcUserId[2]
          data.startTime = `${this.form.time1.date} ${this.form.time1.hour}:${this.form.time1.minute}:00`
          data.devSn = deviceItem.devSn
          data.jdcHsId = deviceItem.hsId
          data.devId = deviceItem.jdlDeviceId;
          try {
             const res = await operationDevice(data)
             Object.assign(this.$data,this.$options.data())
             this.$refs["form"].resetFields()
             this.password = res.data.result
             return false
          } catch (error) {
            this.isError(error,this)
            this.loading = false
            return false
          }
          
      },
      selectMode(){
        switch(this.form.encryptMode){
          case this.limitOptions1[0].key:
              this.max = 9
            break
          case this.limitOptions1[1].key:
              Object.assign(this.form.time1,this.$options.data().form.time1)
              this.max = 168
            break
          case this.limitOptions1[2].key:
              Object.assign(this.form.time1,this.$options.data().form.time1)
              this.max = 750
            break
        }
        this.form.jdcMaxUnlockingTimes = ""
      },
      changeDate(){
        const time = dateFtt('yyyy-MM-dd',new Date())
        console.log(this.form.time1.date > time,time,this.form.time1.date);
        if(this.form.time1.date > time){
          for(let i of this.optionsHours){
            i.disabled = false
          }
          for(let i of this.optionsMinute){
            i.disabled = false
          }
        }else{
          for(let i of this.optionsHours){
            i.disabled = i.label < dateFtt('hh',new Date()) ? true : false
          }
        }
      },
      numberChange(){
        const offlineUnlockTimes = this.form.jdcMaxUnlockingTimes
        switch(this.form.encryptMode){
          case this.limitOptions1[0].key:
              if (offlineUnlockTimes > 9 || offlineUnlockTimes < 1 || offlineUnlockTimes % 1 != 0) {
                this.$message.warning('请输入1-9的次数')
                this.form.jdcMaxUnlockingTimes = ''
              }
            break
          case this.limitOptions1[1].key:
              if (offlineUnlockTimes > 168 || offlineUnlockTimes < 1 || offlineUnlockTimes % 1 != 0) {
                this.$message.warning('请输入1-168的小时')
                this.form.jdcMaxUnlockingTimes = ''
              }
            break
          case this.limitOptions1[2].key:
            if (offlineUnlockTimes > 750 || offlineUnlockTimes < 1 || offlineUnlockTimes % 1 != 0) {
              this.$message.warning('请输入1-750的天数')
              this.form.jdcMaxUnlockingTimes = ''
            }
            break
        }
        this.form.time1.date = dateFtt('yyyy-MM-dd', new Date())
        this.form.time1.hour = dateFtt('hh', new Date())
        let minutesSec = dateFtt('mm:ss', new Date())
        if (minutesSec >= '00:00' && minutesSec <= '19:59') {
          this.form.time1.minute = '00'
        } else if (minutesSec >= '20:00' && minutesSec <= '39:59') {
            this.form.time1.minute = '20'
        } else if (minutesSec >= '40:00' && minutesSec <= '59:59') {
            this.form.time1.minute = '40'
        }
      },
    },
    render(h) {
      const labelText = this.limitOptions1[this.form.encryptMode].name
      const dateSelectDisabled = this.form.jdcMaxUnlockingTimes ? false : true
      return (
        <div style="display:flex;" v-loading={ this.loading }>
          <el-form label-width="80px" props={ { model: this.form,rules } } ref="form">
            <el-form-item label="用户" prop="jdcUserId">
              <el-row>
                  <SelectUser style="width:180px" size="small" vModel={this.form.jdcUserId} />
              </el-row>
            </el-form-item>
            <el-form-item label="限制">
              <el-select style="width:180px" onChange={ this.selectMode } size="small" vModel={this.form.encryptMode}>
                {this.limitOptions1.map((item) => (
                  <el-option key={item.key} label={`${item.name}${this.text.slice(4,6)}`} value={item.key} />
                ))}
              </el-select>
            </el-form-item>
            <el-form-item label={labelText} prop="jdcMaxUnlockingTimes" rules={ rulesGenerator(`请输入${labelText}`) }>
              <el-input placeholder={ `请输入${labelText}` } onChange={ this.numberChange } vModel={this.form.jdcMaxUnlockingTimes} style="width:180px" size="small" />
            </el-form-item>
            {labelText != "次数" ? (
              <div>
                <el-form-item label="生效时间">
                  <el-row>
                    <el-col span={8}>
                      <el-date-picker
                      disabled={ dateSelectDisabled }
                        style="width: 150px;"
                        size="small"
                        onChange={this.changeDate}
                        vModel={this.form.time1.date}
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        type="date"
                        placeholder="选择日期"
                        picker-options={dateLimit}
                      ></el-date-picker>
                    </el-col>
                    <el-col span={8}>
                      <el-select disabled={ dateSelectDisabled }  style="width: 150px;" placeholder="选择小时" size="small" vModel={this.form.time1.hour}>
                        {this.optionsHours.map((item) => (
                          <el-option disabled={ item.disabled } key={item.value} label={item.label} value={item.value} />
                        ))}
                      </el-select>
                    </el-col>
                    <el-col span={8}>
                      <el-select
                        disabled={ dateSelectDisabled }
                        style="width: 150px;"
                        placeholder="选择分钟"
                        size="small"
                        vModel={this.form.time1.minute}
                      >
                        {this.optionsMinute.map((item) => (
                          <el-option key={item.value} label={item.label} value={item.value} />
                        ))}
                      </el-select>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="失效时间">
                  <TimeBox>{ this.date }日/{ this.hour }时/{ this.minute }分</TimeBox>
                </el-form-item>
              </div>
            ) : null}
            <el-form-item>
              <TipBox>tip: { this.form.mode }最大为<span style="color:green;">{ this.max }</span>,离线{this.text.slice(4,6)}自动生成</TipBox>
            </el-form-item>
            {this.password ? (
                  <el-form-item label="密码">
                    <PasswordBox><span style="color: red;">{this.password}</span></PasswordBox>
                  </el-form-item>
                ) : null}
          </el-form>
        </div>
      );
    },
  };