import SecurityGateway from './securityGateway.vue'

function securityGatewayGenerator(h,devSecondType,jdlDeviceId,deviceInfo){
    const strategies = {
        8:<SecurityGateway deviceInfo={ deviceInfo }/>
    }
    if(strategies[devSecondType]){
        return strategies[devSecondType]
    }
    return <SecurityGateway deviceInfo={deviceInfo}/>
}

export default securityGatewayGenerator