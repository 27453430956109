import { render, staticRenderFns } from "./controlBox.vue?vue&type=template&id=8fb6aaf0&scoped=true&"
import script from "./controlBox.vue?vue&type=script&lang=js&"
export * from "./controlBox.vue?vue&type=script&lang=js&"
import style0 from "./controlBox.vue?vue&type=style&index=0&id=8fb6aaf0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fb6aaf0",
  null
  
)

export default component.exports