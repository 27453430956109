<template>
    <Switch1 :deviceId="deviceId">
        <el-button @click="pair">配对</el-button>
    </Switch1>
</template>

<script>
import Switch1 from './switch1.vue'
import { simpleOperationMixin } from '../mixin'
export default {
    props:{
        deviceId:Number,
    },
    mixins:[simpleOperationMixin],
    components:{
        Switch1
    }
}
</script>