<template>
  <div>
      <el-button type="primary" size="mini" @click="getData">手动刷新列表</el-button>
      <el-table
        v-loading="loading"
        :data="tableData"
        height="403px"
        highlight-current-row
      >
        <el-table-column
          prop="popName"
          label="人员"
          min-width="60px"
        ></el-table-column>
        <el-table-column
          prop="devNickname"
          label="设备名称"
          min-width="80px"
        ></el-table-column>
        <el-table-column
          prop="devAddress"
          label="设备安装地址"
          min-width="100px"
        ></el-table-column>
        <el-table-column
          prop="jpicRecordType"
          label="插卡类型"
          min-width="50px"
        ></el-table-column>
        <el-table-column
          prop="jpicType"
          label="取电类型"
          min-width="60px"
        ></el-table-column>
        <el-table-column
          prop="jpicResult"
          label="取电结果"
          min-width="60px"
        ></el-table-column>
        <el-table-column
          prop="jpicBeginTime"
          label="插卡时间"
          width="157px"
        ></el-table-column>
        <el-table-column
          prop="jpicEndTime"
          label="拔卡时间"
          width="157px"
        ></el-table-column>
      </el-table>
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="params.limit"
        :current-page="params.cursor"
        @current-change="changeCurrent"
        @size-change="changeSize"
        :page-sizes="[5, 10, 20, 40, 100]"
        background
        align="center"
      >
      </el-pagination>
  </div>
</template>

<script>
import { plugInCards } from '../../api/device'
export default {
    props:{
        deviceInfo:Object,
    },
    data(){
        return {
            loading:false,
            tableData:[],
            params:{
                sn:this.deviceInfo.devSn,
                limit:5,
                cursor:1
            },
            total:null
        }
    },
    methods:{
        getData(){
            this.loading = true
            plugInCards(this.params).then(res => {
                this.loading = false
                this.tableData = res.data.result.records || []
                this.total = res.data.result.total
            }).catch(err => {
                this.loading = false
                this.isError(err,this)
            })
        },
        changeCurrent(value){
            this.params.cursor = value
            this.getData()
        },
        changeSize(value){
            this.params.limit = value
            this.getData()
        }
    }
}
</script>