<template>
    <div class="floor-heating__box">
        <el-radio-group v-model="radio">
            <el-radio-button label="室温模式" />
            <el-radio-button label="地面模式" />
            <el-radio-button label="时间模式" />
        </el-radio-group>
        <div class="set-temperature">设定温度(&#8451;)</div>
        <div class="zero">
            0
        </div>
        <div class="testing-temperature">监测温度: 0(&#8451;)</div>
        <div class="start">启动</div>
        <div>
            <div class="floor-heating__state">
                <div class="item">
                    <div>关</div>
                    <div>加热开关</div>
                </div>
                <div class="item">
                    <div>室温模式</div>
                    <div>当前运行模式</div>
                </div>
                <div class="item">
                    <div>禁用</div>
                    <div>当前报警模式</div>
                </div>
            </div>
            <div>
                <el-button style="width:100px" type="info">开关 (关)</el-button>
                <el-button style="width:100px" type="info">设备锁 (关)</el-button>
                <el-button style="width:100px" type="info">设 置</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { getBatchState, operationDevice } from '../../api/device'
export default {
    props:{
        deviceId:Number
    },
    data(){
        return {
            radio:"室温模式"
        }
    },
    methods:{
        getData(){
           getBatchState({ ids:this.deviceId }).then(res => {
               console.log(res)
           }).catch(err => {
               this.isError(err,this)
           })
        }
    },
}
</script>

<style scoped lang="scss">
.floor-heating__box{
    display: flex;
    flex-direction: column;
    align-items: center;
    .zero{
      font-size:56px;  
      color: green;  
      border-bottom:1px solid #272822;
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .set-temperature{
      margin-top: 10px;
      color: green;  
    }
    .testing-temperature{
        margin: 8px 0;
        color: green;
    }
    .start{
        margin-bottom: 10px;
        width: 140px;
        border-radius: 40px;
        background-color: #409eff;
        text-align: center;
        color: white;
        padding: 10px 0;
    }
}
.floor-heating__state{
    display: flex;
    margin-bottom: 10px;
    .item{
        margin-left: 10px;
        &:first-child{
            margin-left: 0;
        }
        width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
</style>