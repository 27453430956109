<template>
  <el-dialog
    title="查看设计图"
    :visible.sync="dialogVisible"
    :width="dialogWidth"
    :before-close="handleSeeClose"
  >
    <div>
      <div class="seedialog__header">
        <div>
          <span>所在地址:</span>
          <span style="color: green">
            {{ designDraftDetail.hsAddCity }}
            {{ designDraftDetail.hsAddDistrict }}
            {{ designDraftDetail.hsAddDoorplateno }}</span
          >
        </div>
        <div>
          <span>设计图名称:</span>
          <span style="color: green">{{ designDraftDetail.jctName }}</span>
        </div>
        <div>
          <span>设备个数:</span>
          <span style="color: green">{{ deviceList.length }}</span>
        </div>
      </div>
      <div class="content">
        <DesignDraftBox
          :isMove="false"
          :bgStyle="bgStyle"
          :deviceList="deviceList"
          :clickFunc="deviceClick"
        />
      </div>
    </div>
    <DeviceOperationDialog
      append-to-body
      ref="deviceOperationDialog"
      :deviceInfo="deviceInfo"
      :width="width"
      :dialogVisible="deviceOperationVisible"
      @handleDeviceOperationDialog="handleDeviceOperationDialog"
    />
  </el-dialog>
</template>

<script>
import DesignDraftBox from "./DesignDraftBox.vue";
import DeviceOperationDialog from "../../../device/index.jsx";
import { setWidth } from '../../../device/utils'
export default {
  props: {
    dialogVisible: Boolean,
  },
  components: {
    DesignDraftBox,
    DeviceOperationDialog,
  },
  data() {
    return {
      bgStyle: {},
      img: new Image(),
      deviceList: [],
      editFlag: false,
      dialogWidth: "1200px",
      designDraftDetail: {},
      deviceOperationVisible: false,
      deviceInfo:{},
      width:'700px'
    };
  },
  methods: {
    handleDeviceOperationDialog() {
      this.deviceOperationVisible = false;
    },
    handleSeeClose() {
      this.$emit("handleSeeClose");
    },
    deviceClick(deviceInfo) {
      console.log("触发");
      this.width = setWidth(deviceInfo.devFirstType)
      this.deviceInfo = deviceInfo
      this.deviceOperationVisible = true;
    },
    
    getData(data) {
      console.log(data);
      this.designDraftDetail = data;
      this.img.src = data.jctUrl;
      this.img.onload = () => {
        this.dialogWidth = this.img.width + "px";
        this.deviceList = data.jctDeviceLocations;
        this.bgStyle = {
          background: `url(${data.jctUrl})`,
          height: this.img.height + "px",
          width: this.img.width + "px",
        };
      };
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
}
.seedialog__header {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-around;
  margin-bottom: 20px;
}
.box {
  position: relative;
  .drag {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: move;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: red;
  }
}
</style>