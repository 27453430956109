<template>
  <div>
    <el-form label-position="right" label-width="200px">
      <el-form-item label="状态:">
        <el-switch
          v-model="form.devState"
          active-color="#13ce66"
          inactive-color="#ff4949"
        />
        <span class="msg">{{ msg }}</span>
      </el-form-item>
      <el-form-item label="延迟执行时间:">
        <el-input-number v-model="form.delayTime" :min="0" :max="15" />
      </el-form-item>
      <el-form-item label="持续执行时间:">
        <el-input-number v-model="form.actionTime" :min="0" :max="15" />
      </el-form-item>
      <el-form-item>
        <div class="el-upload__tip" style="color: green">
          时间最大<span class="red">15</span>S, 最小<span class="red">0</span>S哦!
        </div>
      </el-form-item>
      <el-form-item>
        <el-button :loading="btnLoading" type="primary" @click="clickBtn">执行</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { operationDevice } from '../../api/device'
export default {
  props:{
    deviceId:Number
  },
  data() {
    return {
      btnLoading:false,
      form: {
        devId:this.deviceId,
        actionTime: 0,
        delayTime: 0,
        devState: false,
      },
    };
  },
  methods: {
      clickBtn(){
        // 使用深拷贝
        let data = JSON.parse(JSON.stringify(this.form))
        data = {...data,devState:this.form.devState ? '开' : '关'}
        console.log(data)
        this.btnLoading = true
        operationDevice(data).then(res => {
          this.btnLoading = false
          this.$message({
            message:'控制成功',
            type:'success'
          })
          this.countDown()
        }).catch(err => {
          this.btnLoading = false
          this.isError(err,this)
        })
      },
      countDown(){
        new Promise(resolve => {
            const inter = setInterval(() => {
             if(this.form.delayTime <= 0){
                 clearInterval(inter)
                 resolve()
                 return
             }
             this.form.delayTime--
         },1000)
        }).then(() => {
            this.form.actionTime--
            const inter1 = setInterval(() => {
             if(this.form.actionTime <= 0){
                 clearInterval(inter1)
                 return
             }
             this.form.actionTime--
            },1000)
        })
      },
  },
  computed:{
      "msg"(){
          return `${this.form.delayTime}秒后执行${this.form.devState ? '开' : '关'}, 持续${this.form.actionTime}秒`
      }
  },
};
</script>

<style scoped>
.red{
    color: red;
}
.msg{
    display: inline-block;
    margin-left: 20px;
}
</style>