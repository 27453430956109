<template>
    <div class="door-lock__btnbox">
        <!-- @click="handleClick(item)" -->
        <el-button
      type="primary"
      style="width: 130px"
      v-for="item in buttonList6200"
      :key="item.key"
      >{{ item.text }}</el-button
    >
    </div>
</template>

<script>
import { buttonList6200 } from './common'
export default {
    data(){
        return {
            buttonList6200
        }
    },
}
</script>


<style scoped lang="scss">
.door-lock__btnbox {
  button:first-child {
    margin-left: 10px;
  }
  button {
    margin-top: 10x;
    margin-bottom: 10px;
  }
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
</style>