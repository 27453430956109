import { operationDevice,deviceMatching } from '../api/device'

export const simpleOperationMixin = {
    methods:{
        clickBtn(state){
            operationDevice({ devId:this.deviceId,devState:state }).then(res => {
                this.$message({
                    type:"success",
                    message:'控制成功'
                })
            }).catch(err => {
                this.isError(err,this)
            })
        },
        pair(){
            deviceMatching(this.deviceId).then(res => {
                this.$message({
                    type:'success',
                    message:'配对成功'
                })
            }).catch(err => {
                this.isError(err,this)
            })
        }
    }
}

export const sliderMixin = {
    methods:{
        changeSlider(){
            operationDevice(this.form).then(res => {
                this.$message({
                    type:"success",
                    message:'控制成功'
                })
            }).catch(err => {
                this.isError(err,this)
            })
        }
    }
}
