import {
  ContentOper1,
  ContentOper2,
  ContentOper3,
  ContentOper15,
  ContentOper16,
  ContentOper17,
} from "./content.jsx";

import ContentOper5And7 from './contentOper5And7'

export default {
  props: {
    info: Object,
    dialogVisible: Boolean,
    deviceInfo:Object
  },
  created() {
    console.log(this.props);
  },
  methods: {
    handleClose(flag) {
      if(flag === "确定"){
        this.$refs['contentOper'].submit(this.deviceInfo).then(res => {
          if(res){
            this.$emit("doorLockOperationDialogClose")
          }
        })
        return
      }
      this.$emit("doorLockOperationDialogClose")
    },
    initChildrenData(){
      this.$nextTick(() => {
        if(this.$refs['contentOper'].initData){
          this.$refs['contentOper'].initData(this.deviceInfo)
        }
      })
    }
  },
  render(h) {
    const ContentOper = contentOperGenerator(this.info.key);
    return (
      <el-dialog
        title={this.info.text}
        visible={this.dialogVisible}
        width={this.$attrs.width}
        append-to-body
        before-close={this.handleClose}
      >
        <ContentOper ref="contentOper" text={ this.info.text }/>
        {this.info.buttonText ? (
          <span slot="footer" class="dialog-footer">
            <el-button onClick={this.handleClose}>取 消</el-button>
            <el-button type="primary" onClick={() => {
              this.handleClose('确定')
            }}>
              {this.info.buttonText}
            </el-button>
          </span>
        ) : null}
      </el-dialog>
    );
  },
};

function contentOperGenerator(tag) {
  const strategies = {
    1: ContentOper1,
    2: ContentOper2,
    3: ContentOper3,
    5: ContentOper5And7,
    7: ContentOper5And7,
    15:ContentOper15,
    16: ContentOper16,
    17: ContentOper17,
  };
  return strategies[tag];
}
