export const stateList = [{
    key:'aw',
    company:'功率/W'
  },{
    key:'aa',
    company:'电流/mA'
  },{
    key:'av',
    company:'电压/V',
  },{
    key:'at',
    company:'温度/C'
  },{
    key:'power',
    company:'总电量/Kw.H'
  },{
    key:'mxgg',
    company:'限定功率/W',
    setting:true,
    width:'500px',
    buttonText:true,
    flag:'功率'
  },{
    key:'mxgl',
    company:'限定电流/A',
    setting:true,
    width:'500px',
    buttonText:true,
    flag:'电流'
  },{
    company:'当前状态',
    key:'online',
    circular:true
  },{
    key:'ald',
    company:'漏电流'
  },{
    key:'enableCheck',
    company:'漏电自检',
    setting:true,
    flag:'自检',
    buttonText:true,
    special:true,
    width:'800px',
  }]
export const settingList = [
    { setting:true,flag:'电箱报警记录',key:'caution',width:'1000px' },
    { setting:true,flag:'网络设置',key:'wifi',width:'400px',buttonText:true, },
    { setting:true,flag:'详细参数',key:'log',width:'1000px' },
    { setting:true,flag:'线路名称修改',key:'change',width:'500px',buttonText:true, }
] 


export const detailParamsState = [
  {label: '电箱编号',key: 'mac',},
  {label: '线路地址',key: 'addr',},
  {label: '线路名称',key: 'title',},
  {label: '线路有效性',key: 'validity',},
  {label: '线路开关状态',key: 'oc',},
  {label: '远程控制合闸分闸',key: 'enableNetCtrl',},
  {label: '在线状态',key: 'stateInformation',},
  {label: '当前电量',key: 'power',},
  {label: '过功门限值',key: 'mxgg',},
  {label: '过流门限值',key: 'mxgl',},
  {label: '类型',key: 'lineType',},
  {label: '告警状态',key: 'alarm',},
  {label: '线路规格',key: 'specification',},
  {label: '控制标记',key: 'control',},
  {label: '显示标志',key: 'visibility',},
  {label: '总线标记',key: 'mainLine',},
  {label: '漏电流',key: 'ald',},
  {label: '电流',key: 'aa',},
  {label: '温度',key: 'at',},
  {label: '电压',key: 'av',},
  {label: '功率',key: 'aw',},
  {label: '功率因素',key: 'apf',},
  {label: '平均电流',key: 'ga',},
  {label: '壳温度',key: 'gt',},
  {label: '平均电压',key: 'gv',},
  {label: '功率和值',key: 'gw',},
  {label: '更新时间',key: 'updateTime',},
]