import ControlBox from './controlBox.vue'
import FreshAir from './freshAir.vue'

function controlBoxGenertor(h,devSecondType,jdlDeviceId){
    const strategies = {
       144:<ControlBox deviceId={ jdlDeviceId }/>,
       147:<FreshAir deviceId={ jdlDeviceId }/>
    }
    if(strategies[devSecondType]){
        return strategies[devSecondType]
    }
    return <ControlBox deviceId={ jdlDeviceId }/>
}

export default controlBoxGenertor