import switchGenerator from './switch'
import scoketGenerator from './socket'
import doorLockGenertor from './doorLock'
import airConditionerGenertor from './airConditioner'
import curtainGenertor from './curtain'
import securityGatewayGenerator from'./securityGateway'
import infraredAlarmGenertor from './infraredAlarm'
import doorWarningAlarmGenertor from './doorWarningAlarm'
import electricBoxGenertor from './electricBox'
import scenePanelGenertor from './scenePanel'
import onlineCardPanelGenertor from './onlineCardPanel'
import controlBoxGenertor from './controlBox'
import lampGenerator from './lamp'
import clothesHangerGenerator from './clothesHanger'
import adjustingLampGenertor from './adjustingLamp'
import floorHeatingGenertor from './floorHeating'
import centralizedControlGenertor from './centralizedControl'
import sensorGenertor from './sensor'

const deviceOperationGenerator = function(h,devFirstType,devSecondType,jdlDeviceId,deviceInfo){
    const components = {
      // 开关
      1:() => switchGenerator(h,devSecondType,jdlDeviceId),
      // 插座
      2:() => scoketGenerator(h,devSecondType,jdlDeviceId),
      // 门锁
      3:() => doorLockGenertor(h,devSecondType,jdlDeviceId,deviceInfo),
      // 空调
      4:() => airConditionerGenertor(h,devSecondType,jdlDeviceId),
      // 窗帘
      6:() => curtainGenertor(h,devSecondType,jdlDeviceId),
      // 安防网关
      8:() => securityGatewayGenerator(h,devSecondType,jdlDeviceId,deviceInfo),
      // 红外报警器
      10:() => infraredAlarmGenertor(h,devSecondType,jdlDeviceId,deviceInfo),
      // 门磁报警器
      13:() => doorWarningAlarmGenertor(h,devSecondType,jdlDeviceId,deviceInfo),
      // 电箱
      16:() => electricBoxGenertor(h,devSecondType,jdlDeviceId),
      // 情景面板
      17:() => scenePanelGenertor(h,devSecondType,jdlDeviceId,deviceInfo.hsId),
      // 插卡面板
      18:() => onlineCardPanelGenertor(h,devSecondType,jdlDeviceId,deviceInfo),
      // 灯 此二级类型组件 被一级类型为31的设备所复用 请注意修改
      23:() => lampGenerator(h,devSecondType,jdlDeviceId),
      //晾衣架
      25:() => clothesHangerGenerator(h,devSecondType,jdlDeviceId),
      // 调节灯 此二级类型组件复用了 一级类型为23的二级类型组件 请注意修改
      31:() => adjustingLampGenertor(h,devSecondType,jdlDeviceId),
      // 地暖 未完成
      30:() => floorHeatingGenertor(h,devSecondType,jdlDeviceId),
      // 集控
      37:() => centralizedControlGenertor(h,devSecondType,jdlDeviceId),
      // 传感器
      40:() => sensorGenertor(h,devSecondType,jdlDeviceId,deviceInfo),
      // 控制盒
      41:() => controlBoxGenertor(h,devSecondType,jdlDeviceId),
      notfound:() => <div style="color:green;font-size:16px;">功能正在加紧开发敬请稍后...</div>
    }
    // 容错处理
    if(components[devFirstType]){
      return components[devFirstType]
    }
    return components['notfound']
  }
export default deviceOperationGenerator