// [Vue warn]: Do no./switch1.vuein or reserved HTML elements as component id: Switch
import Switch1 from './switch1.vue'
import Switch75 from './switch75.vue'

function switchGenerator(h,devSecondType,jdlDeviceId){
    const strategies = {
        75:<Switch75 deviceId={ jdlDeviceId } />
    }

    if(strategies[devSecondType]){
        return strategies[devSecondType]
    }
    return <Switch1 deviceId={ jdlDeviceId }/>
}

export default switchGenerator
