export function deviceInfoLog(deviceInfo){
    const attributes = [{
        text:'一级类型',
        name:'devFirstType'
    },{
        text:'二级类型',
        name:'devSecondType'
    },{
        text:'设备id',
        name:'jdlDeviceId'
    }]
    if(JSON.stringify(deviceInfo) === "{}") return
    console.log(deviceInfo)
    for(let i =0; i < attributes.length; i++){
        if(typeof deviceInfo[attributes[i]['name']] != 'number'){
            console.error(`${deviceInfo[attributes[i]['name']]}校验不通过期待为Number可实际为${typeof deviceInfo[attributes[i]['name']]}`)
        }
        console.log(`${attributes[i]['text']}:${deviceInfo[attributes[i]['name']]}`)
    }
}

export function timeGenerator(number,interval = 1,start = 0,){
    let time = []
    for(let i = start;i < number;i += interval){
        if(i >= 10){
            time.push({
                value:`${i}`,
                label:`${i}`
            })
        }else{
            time.push({
                value:`${i}`,
                label:`0${i}`
            })
        }
    }
    return time
}


export function setWidth(devFirstType){
    const strategies = {
      18:'1050px',
      30:'500px',
      40:'800px',
      notfound:'700px'
    }
    if(strategies[devFirstType]){
      return strategies[devFirstType]
    }
    return strategies['notfound']
}