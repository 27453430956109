export const validateUserCoding = (rule, value, callback) => {
    if (value[2] == undefined) {
        callback(new Error('不知道你要给谁授权呀!'))
    }
    callback()
}

export const password = [{
    required: true,
    message: '密码不能为空哦',
    trigger: 'blur',
},
{
    min: 6,
    max: 10,
    message: '长度为6-10哦',
    trigger: 'blur',
}
]

export const rulesGenerator = (msg) => {
    return [{ required: true, message: msg, trigger: ["blur", "change"] }];
  };

export const dateLimit = {
    disabledDate(time) {
      return time.getTime() < Date.now() - 8.64e7;
    },
  };