<template>
  <div class="visualization-device__content">
    <div class="button__box">
      <el-button @click="addDraftDialogVisible = true" type="primary"
        >添加设计图</el-button
      >
    </div>
    <div class="search__box">
      <el-form label-width="80px">
        <el-row>
          <el-col :span="4">
            <el-form-item label="设计图名称">
              <el-input
                size="mini"
                clearable
                @change="getCurtainInfoData"
                v-model="params.jctName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="房间名称">
              <el-input
                size="mini"
                clearable
                @change="getCurtainInfoData"
                v-model="params.hsAddCommunity"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="状态">
               <el-select size="mini" clearable @change="getCurtainInfoData" v-model="params.jctState" placeholder="请选择">
                  <el-option
                    v-for="item in [{label:'正常',value:'正常'},{label:'注销',value:'注销'}]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-divider></el-divider>
    <el-table
      v-loading="loading"
      :data="tableData"
      :height="tableHeight"
      @row-dblclick="rowdbClick"
      border
      style="width: 100%"
    >
      <el-table-column prop="hsAddCommunity" label="房间" width="180">
      </el-table-column>
      <el-table-column prop="devSum" label="设备个数" width="180">
      </el-table-column>
      <el-table-column prop="jctName" label="设计图名称"> </el-table-column>
      <el-table-column prop="jctState" label="状态"> 
        <template slot-scope="scope">
          <span :style="{color:colorGenerator(scope.row.jctState)}">{{ scope.row.jctState }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="jctTime" label="创建时间">
        <template slot-scope="scope">
          <span style="color: green">{{ scope.row.jctTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)" style="margin-right:10px;">编辑</el-button>
          <el-popconfirm
          @confirm="handleDelete(scope.row.jctId)"
            title="你确定要注销这个设计图？"
          >
            <el-button slot="reference" size="mini" type="danger">注销</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      align='center'
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="params.current"
      :page-sizes="[10, 20, 50,100]"
      :page-size="params.limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <AddDesignDraft
      ref="addDesignDraft"
      :dialogVisible="addDraftDialogVisible"
      @handleAddClose="handleAddDraftClose"
    />
    <SeeDesignDraft
      ref="seeDesignDraft"
      :dialogVisible="seeDialogVisible"
      @handleSeeClose="handleSeeClose"
    />
  </div>
</template>

<script>
import AddDesignDraft from "./visualizationDevice-cpns/AddDesignDraft.vue";
import SeeDesignDraft from "./visualizationDevice-cpns/SeeDesignDraft.vue";
import { getCurtainInfo,deleteCurtainInfo } from "../../api/visualizationDevice";
export default {
  data() {
    return {
      loading: false,
      addDraftDialogVisible: false,
      seeDialogVisible: false,
      tableHeight:window.innerHeight - 280,
      params: {
        hsAddCommunity: "",
        jctName: "",
        current: 1,
        params:'',
        limit:10,
        jctState:'正常'
      },
      total:null,
      tableData: [],
      rowData: null,
    };
  },
  components: {
    AddDesignDraft,
    SeeDesignDraft,
  },
  created() {
    this.getCurtainInfoData();
  },
  methods: {
    colorGenerator(text){
      switch(text){
        case '注销':
          return 'red'
        case '正常':
          return 'green'
      }
    },
    handleSizeChange(val){
      this.params.limit = val
      this.getCurtainInfoData()
    },
    handleCurrentChange(val){
      this.params.current = val
      this.getCurtainInfoData()
    },
    handleEdit(row) {
      this.addDraftDialogVisible = true;
      this.$nextTick(() => {
        this.$refs["addDesignDraft"].editDesignDraft(row);
      });
    },
    handleDelete(jctId) {
      deleteCurtainInfo({jctId}).then(res => {
        this.$message({
          type:'success',
          message:'注销成功'
        })
        this.getCurtainInfoData()
      }).catch(err => {
        this.isError(err,this)
      })
    },
    getCurtainInfoData() {
      this.loading = true;
      console.log(this.params);
      getCurtainInfo(this.params)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.result.records;
          this.total = res.data.result.total;
        })
        .catch((err) => {
          this.loading = false;
          this.isError(err, this);
          this.tableData = [];
        });
    },
    handleSeeClose() {
      this.seeDialogVisible = false;
    },
    rowdbClick(rowdata) {
      this.seeDialogVisible = true;
      this.$refs["seeDesignDraft"].getData(rowdata);
    },
    handleAddDraftClose(flag) {
      if (flag === true) {
        this.getCurtainInfoData();
      }
      this.addDraftDialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.visualization-device__content {
  /deep/ .el-divider {
    color: #000000;
    height: 2px;
    margin-top: -10px;
    margin-bottom: 10px;
  }
}
.search__box {
  margin-top: 10px;
  margin-left: 10px;
}
.button__box {
  margin: 10px 0 0 10px;
}
.box {
  background: url("../../assets/平面设计图.jpg");
  position: relative;
  .drag {
    cursor: move;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: red;
  }
}
</style>
