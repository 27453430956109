<template>
    <Lamp :deviceId="deviceId">
        <el-form label-position="right" >
            <el-form-item label-width="130px" label="亮度:">
                <el-slider @change="changeSlider" v-model="form.devBrightness" :min="1" :step="10" :max="99" style="width: 80%"/>
            </el-form-item>
        </el-form>
    </Lamp>
</template>

<script>
import Lamp from '../lamp/lamp.vue'
import { sliderMixin } from '../mixin'
export default {
    props:{
        deviceId:Number
    },
    data(){
        return {
            form:{
                devId:this.deviceId,
                devState:'开',
                devBrightness:50,
            }
        }
    },
    mixins:[sliderMixin],
    components:{
        Lamp
    }
}
</script>