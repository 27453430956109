<template>
    <div>
        <AirConditioner :info="deviceInfo" :deviceId="deviceId" @updateDeviceInfo="updateDeviceInfo">
            <div slot="left">
                <div class="info__style" v-for="item in central" :key="item.key">
                {{ item.text }}:{{ deviceInfo[item.key] }}
                </div>
            </div>
        </AirConditioner>
    </div>
</template>

<script>
import AirConditioner from './airConditioner.vue'
import { central } from './common'
export default {
    props:{
        deviceId:Number
    },
    components:{
        AirConditioner
    },
    data(){
        return {
            central,
            deviceInfo:{}
        }
    },
    methods:{
        updateDeviceInfo(info){
            this.deviceInfo = info
        }
    }
}
</script>

<style scoped>
.info__style {
  color: green;
  font-size: 14px;
  margin: 20px 0 20px 0;
}
</style>