<template>
  <div>
    <InfraredAlarm :deviceInfo="deviceInfo">
      <div slot="button">
        <el-popover
          v-for="item in buttonList"
          :key="item.id"
          placement="bottom"
          width="160"
          v-model="item.flag"
        >
          <div
            v-if="visible === 1"
            style="text-align: center"
            class="popover-box"
          >
            <el-button type="primary" style="width: 100%" size="mini"
            @click="operClick('保持')"
              >保持</el-button
            >
            <el-button type="primary" style="width: 100%" size="mini"
            @click="operClick('开')"
              >开</el-button
            >
            <el-button type="primary" style="width: 100%" size="mini"
            @click="operClick('关')"
              >关</el-button
            >
          </div>
          <div
            v-if="visible === 2"
            style="text-align: center"
            class="popover-box"
          >
            <el-input-number
              style="width: 80%"
              size="mini"
              placeholder="请输入1-10"
              v-model="params.spacing"
              controls-position="right"
              :min="1"
              :max="10"
            ></el-input-number> 秒
            <el-button type="primary" style="width: 100%" size="mini"
            @click="operClick('事件周期')"
              >确定</el-button
            >
          </div>
          <div
            v-if="visible === 3"
            style="text-align: center"
            class="popover-box"
          >
            <el-input-number
              style="width: 100%"
              size="mini"
              placeholder="请输入1-65535"
              v-model="params.threshold"
              controls-position="right"
              :min="1"
              :max="65535"
            ></el-input-number>
            <el-button type="primary" style="width: 100%" size="mini"
            @click="operClick('报警阙值')"
              >确定</el-button
            >
          </div>
          <div
            v-if="visible === 4"
            style="text-align: center"
            class="popover-box"
          >
            <el-input-number
              style="width: 100%"
              size="mini"
              v-model="params.sensitivity"
              placeholder="请输入1-255"
              controls-position="right"
            ></el-input-number>
            <el-button type="primary" style="width: 100%" size="mini"
            @click="operClick('灵敏度等级')"
              >确定</el-button
            >
          </div>
          <el-button @click="clickBtn(item.id)" slot="reference" type="primary"
            >{{ item.text
            }}<i
              :class="[
                'el-icon--right',
                item.flag ? 'el-icon-arrow-up' : 'el-icon-arrow-down',
              ]"
            ></i
          ></el-button>
        </el-popover>
      </div>
    </InfraredAlarm>
  </div>
</template>

<script>
import InfraredAlarm from "./infraredAlarm.vue";
import { buttonList } from "./common";
import { operationDevice } from '../../api/device'

export default {
  props: {
    deviceInfo: Object,
  },
  data() {
    return {
      buttonList,
      visible: 0,
      params:{
        devId:this.deviceInfo.jdlDeviceId,
        sensitivity:0,
        spacing:3,
        switchs:2,
        threshold:50
      }
    };
  },
  components: {
    InfraredAlarm,
  },
  methods: {
    clickBtn(id) {
      this.visible = id;
    },
    operClick(flag){
      switch(flag){
        case "保持":
          this.params.switchs = 0
          break
        case "开":
          this.params.switchs = 1
          break
        case "关":
          this.params.switchs = 2
          break
      }
      operationDevice(this.params).then(res => {
        this.$message({
          type:'success',
          message:'操作成功'
        })
      }).catch(err => {
        this.isError(err,this)
      })
    }
  },
};
</script>

<style scoped>
.popover-box /deep/ .el-button {
  margin-left: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
