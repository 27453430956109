import Socket from './socket.vue'
import Socket25 from './socket25.vue'

function scoketGenerator(h,devSecondType,jdlDeviceId){
    const strategies = {
        25:<Socket25 deviceId={ jdlDeviceId }/>,
        138:<div>138</div>
    }
    if(strategies[devSecondType]){
        return strategies[devSecondType]
    }
    return <Socket deviceId={jdlDeviceId}/>
}

export default scoketGenerator