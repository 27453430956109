import { rulesGenerator } from "@/utils/utils";
import { operationDevice, getPageBoxAlarm, putDeviceDevNickname } from "../../../api/device";
import { timeGenerator } from "../../utils";
import moment from "moment";
import { detailParamsState } from "../common";

const timeList1 = timeGenerator(29, 1, 1);
const timeList2 = timeGenerator(24, 1);
const timeList3 = timeGenerator(56, 5);

// 0 功率
const TYPE = ["SWITCHSET", "SETAUTOLEAK", "SETWIRELESS"];

// 功率
export const PowerContent = {
  data() {
    return {
      form: {
        lineName: "",
        maximumPower: "",
        overflowThreshold: "",
      },
    };
  },
  methods: {
    getData(data) {
      if (JSON.stringify(data) === "{}") return;
      this.form.lineName = data.title;
      this.form.maximumPower = data.mxgg;
      this.form.overflowThreshold = data.mxgl;
    },
    submit(deviceId) {
      if (!this.submitForm(["form"], this)) return Promise.resolve(false);
      const data = {
        type: TYPE[0],
        devId: deviceId,
        ...this.form,
      };
      console.log(data);
      return operationDevice(data)
        .then((res) => {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.$refs["form"].resetFields();
          return true;
        })
        .catch((err) => {
          this.isError(err, this);
          return false;
        });
    },
  },
  render(h) {
    return (
      <el-form label-position="left" props={{ model: this.form }} label-width="150px" ref="form">
        <el-form-item label="线路名称" prop="lineName" rules={rulesGenerator("请输入线路名称")}>
          <el-input v-model={this.form.lineName} size="mini" />
        </el-form-item>
        <el-form-item label="最大功率（单位W）" prop="maximumPower" rules={rulesGenerator("请输入最大功率")}>
          <el-input v-model={this.form.maximumPower} size="mini" />
        </el-form-item>
        <el-form-item label="过流门限值（单位A）" prop="overflowThreshold" rules={rulesGenerator("请输入过流门限值")}>
          <el-input v-model={this.form.overflowThreshold} size="mini" />
        </el-form-item>
      </el-form>
    );
  },
};

// 电流
export const ElectricCurrent = {
  render(h) {
    return <div>电流</div>;
  },
};

// 漏电自检
export const LeakageSelfCheck = {
  data() {
    return {
      form: {
        day: "",
      },
      hour: "",
      minute: "",
      isSelfTest: false,
      deviceState: {},
    };
  },
  methods: {
    getData(deviceState) {
      this.deviceState = deviceState;
    },
    submit(deviceId) {
      if (!this.submitForm(["form"], this)) return Promise.resolve(false);
      const data = {
        type: TYPE[1],
        devId: deviceId,
        lineName: this.deviceState.title,
        maximumPower: this.deviceState.mxgg,
        overflowThreshold: this.deviceState.mxgl,
        enableCheck: this.isSelfTest,
        day: this.form.day,
        hour: this.hour || "0",
        minute: this.minute || "0",
      };
      return operationDevice(data)
        .then((res) => {
          this.$message({
            type: "success",
            message: "自检设置成功",
          });
          this.$refs["form"].resetFields();
          return true;
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
  },
  render(h) {
    return (
      <div>
        <el-form props={{ model: this.form }} ref="form">
          <el-form-item label="启用漏电自检">
            <el-switch v-model={this.isSelfTest} active-color="#13ce66" inactive-color="#ff4949" />
          </el-form-item>
          <el-form-item label="选择自检日期" prop="day" rules={rulesGenerator("请选择自检日期")}>
            <el-row>
              <el-col span={6}>
                <el-select placeholder="选择日期" v-model={this.form.day} size="mini">
                  {timeList1.map((item) => (
                    <el-option key={item.value} label={item.label} value={item.value} />
                  ))}
                </el-select>
              </el-col>
              <el-col span={6}>
                <el-select placeholder="选择小时" v-model={this.hour} size="mini">
                  {timeList2.map((item) => (
                    <el-option key={item.value} label={item.label} value={item.value} />
                  ))}
                </el-select>
              </el-col>
              <el-col span={6}>
                <el-select placeholder="选择分钟" v-model={this.minute} size="mini">
                  {timeList3.map((item) => (
                    <el-option key={item.value} label={item.label} value={item.value} />
                  ))}
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
            <div class="el-upload__tip">
              每月
              <span style="color:green">
                {this.form.day}号-{this.hour}时-{this.minute}分
              </span>
              进行自检
            </div>
          </el-form-item>
          <el-form-item>
            <div class="el-upload__tip">
              tip:该功能使用后,在执行时会导致该路线短暂停电,建议设置时间为<span style="color:red">07:00</span>
              <div>
                上次自检功能为:<span style="color:red;">{this.deviceState.lastCheckDate}</span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    );
  },
};

// 电箱报警记录
// /common2/devices/518/getPageBoxAlarm?startTime=2021-06-29+00:00:00&endTime=2021-06-29+09:53:08&limit=10&cursor=1&total=0&pages=0
export const WarningContent = {
  data() {
    return {
      tableData: [],
      timeArr: null,
      params: {
        startTime: "",
        endTime: "",
        limit: 10,
        cursor: 1,
      },
      total: null,
      deviceId: null,
      loading: false,
    };
  },
  methods: {
    getData(_, deviceId) {
      this.deviceId = deviceId;
      const startTime = moment().format("YYYY-MM-DD 00:00:00");
      const endTime = moment().format("YYYY-MM-DD HH:mm:ss");
      this.timeArr = [startTime, endTime];
      this.getWarningData();
    },
    getWarningData() {
      this.loading = true;
      this.params.startTime = this.timeArr[0];
      this.params.endTime = this.timeArr[1];
      getPageBoxAlarm(this.deviceId, this.params)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.result.records;
          this.total = res.data.result.total;
        })
        .catch((err) => {
          this.loading = false;
          this.isError(err, this);
        });
    },
    cursorChange(value) {
      this.params.cursor = value;
      this.getWarningData();
    },
    limitChange(value) {
      this.params.limit = value;
      this.getWarningData();
    },
  },
  render(h) {
    return (
      <div v-loading={this.loading}>
        <el-date-picker
          v-model={this.timeArr}
          type="datetimerange"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          on-change={this.getWarningData}
        ></el-date-picker>
        <el-table data={this.tableData} style="width: 100%;min-height:400px">
          <el-table-column prop="id" label="记录id"></el-table-column>
          <el-table-column prop="address" label="设备线路地址"></el-table-column>
          <el-table-column prop="name" label="线路名称"></el-table-column>
          <el-table-column prop="type" label="告警类型"></el-table-column>
          <el-table-column prop="time" label="告警时间"></el-table-column>
          <el-table-column prop="content" label="告警内容"></el-table-column>
        </el-table>
        <el-pagination
          small
          layout="total, sizes, prev, pager, next, jumper"
          page-sizes={[5, 10, 20, 40, 100]}
          page-size={this.params.limit}
          total={this.total}
          align="center"
          background
          on-current-change={this.cursorChange}
          on-size-change={this.limitChange}
          style="text-align:center"
        ></el-pagination>
      </div>
    );
  },
};

//NetworkSetting
export const NetworkSetting = {
  data() {
    return {
      wifi: {
        oldName: "",
        newName: "",
        oldPassword: "",
        newPassword: "",
      },
    };
  },
  methods: {
    submit(deviceId) {
      if (!this.submitForm(["form"], this)) return Promise.resolve(false);
      const data = {
        type: TYPE[2],
        devId: deviceId,
        ...this.wifi,
      };
      return operationDevice(data)
        .then((res) => {
          this.$refs["form"].resetFields();
          return true;
        })
        .catch((err) => {
          this.isError(err, this);
          return false;
        });
    },
  },
  render(h) {
    return (
      <el-form props={{ model: this.wifi }} ref="form" label-width="100px">
        <el-form-item label="原WiFi名称" prop="oldName" rules={rulesGenerator("请输入原wifi名称")}>
          <el-input size="mini" v-model={this.wifi.oldName}></el-input>
        </el-form-item>
        <el-form-item label="新WiFi名称" prop="newName" rules={rulesGenerator("请输入新wifi名称")}>
          <el-input size="mini" v-model={this.wifi.newName}></el-input>
        </el-form-item>
        <el-form-item label="原WiFi密码" prop="oldPassword" rules={rulesGenerator("请输入原wifi密码")}>
          <el-input size="mini" v-model={this.wifi.oldPassword}></el-input>
        </el-form-item>
        <el-form-item label="新WiFi密码" prop="newPassword" rules={rulesGenerator("请输入新wifi密码")}>
          <el-input size="mini" v-model={this.wifi.newPassword}></el-input>
        </el-form-item>
        <p class="el-upload__tip">
          tip：WiFi名称只能为英文或数字组成，<span style="color:red;">不能为中文名称</span>。
        </p>
      </el-form>
    );
  },
};

// 修改线路
export const DetailParams = {
  data() {
    return {
      deviceState: {},
    };
  },
  methods: {
    getData(deviceState) {
      this.deviceState = JSON.parse(JSON.stringify(deviceState));
      this.deviceState.oc = this.deviceState.oc ? "开" : "关";
      this.deviceState.enableNetCtrl = this.deviceState.enableNetCtrl ? "允许" : "不允许";
      this.deviceState.validity = this.deviceState.validity ? "有效" : "无效";
      this.deviceState.online = this.deviceState.online ? "在线" : "离线";
      this.deviceState.lineType = this.deviceState.lineType == "220" ? "告警取消" : "存在告警";
      this.deviceState.alarm = this.deviceState.alarm == 0 || 128 ? "允许" : "不允许";
      this.deviceState.gatherAddr = this.deviceState.gatherAddr == -1 ? "进线直连" : this.deviceState.gatherAddr;
      this.deviceState.control = this.deviceState.control ? "是" : "否";
      this.deviceState.visibility = this.deviceState.visibility ? "是" : "否";
      this.deviceState.mainLine = this.deviceState.mainLine == "0" ? "非总线" : "总线";
    },
  },
  render(h) {
    return (
      <div>
        <el-form label-width="120px" style="display:flex;flex-wrap: wrap;">
          {detailParamsState.map((item) => (
            <el-form-item key={item.key} label={item.label}>
              <el-input size="mini" value={this.deviceState[item.key]} readonly></el-input>
            </el-form-item>
          ))}
        </el-form>
      </div>
    );
  },
};

// 修改线路
export const ChangeContent = {
  data(){
    return {
      form:{
        accessRd:''
      }
    }
  },
  methods:{
    submit(deviceId){
      if(!this.submitForm(['form'],this)) return Promise.resolve(false)
      return putDeviceDevNickname(deviceId,{ devNickname:this.form.accessRd }).then(res => {
        this.$refs["form"].resetFields();
        this.$message({
          type:'success',
          message:"修改成功"
        })
        return true
      }).catch(err => {
        this.isErroe(err,this)
        return false
      })
    }
  },
  render(h) {
    return (
      <el-form label-width="80px" props={{ model: this.form}} ref="form">
        <el-form-item label="支路名称" prop="accessRd" rules={ rulesGenerator("请输入支路名称") }>
          <el-input size="mini" v-model={ this.form.accessRd } placeholder="请输入支路名称" />
        </el-form-item>
      </el-form>
    );
  },
};
