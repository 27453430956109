import Lamp from '../lamp/lamp.vue'
// 复用一级类型为23 二级类型为31 的普通灯组件
import AdjustingLamp76 from '../lamp/lamp31.vue'
import AdjustingLamp97 from './adjustingLamp97.vue'

function adjustingLampGenertor(h,devSecondType,jdlDeviceId){
    const strategies = {
        // 色温 亮度
        76:<AdjustingLamp76 deviceId={ jdlDeviceId }/>,
        // 亮度
        97:<AdjustingLamp97 deviceId={ jdlDeviceId }/>
    }
    if(strategies[devSecondType]){
        return strategies[devSecondType]
    }
    return <Lamp deviceId={ jdlDeviceId }/>
}

export default adjustingLampGenertor