export const drag = (el) => {
    let dragBox = el; //获取当前元素
    let imgMakeBox; //父盒子
    let moveW = 0; //移动元素的width
    let moveH = 0; //移动元素的height
    let parentW = 0; //父盒子的width
    let parentH = 0; //父盒子的height
    dragBox.onmousedown = (e) => {
      imgMakeBox = document.getElementById("visualize_device_box"); // 获取父元素
      moveW = dragBox.offsetWidth;
      moveH = dragBox.offsetHeight;
      parentW = imgMakeBox.offsetWidth; //父盒子的width
      parentH = imgMakeBox.offsetHeight; //父盒子的height
      //算出鼠标相对元素的位置
      let disX = e.clientX - dragBox.offsetLeft;
      let disY = e.clientY - dragBox.offsetTop;
      document.onmousemove = (e) => {
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX;
        let top = e.clientY - disY;
        let resW = parentW - moveW; //父盒子宽减去移动盒子的宽 = 最大可移动的区域
        let resH = parentH - moveH; //父盒子高减去移动盒子的高 = 可最大移动的区域
        left = left >= resW ? resW : left <= 0 ? 0 : left;
        top = top >= resH ? resH : top <= 0 ? 0 : top;
        //移动当前元素
        dragBox.style.left = left + "px";
        dragBox.style.top = top + "px";
      };
      document.onmouseup = (e) => {
        //鼠标弹起来的时候不再移动
        document.onmousemove = null;
        //预防鼠标放上去的时候还会移动
        document.onmouseup = null;
      };
    };
  }