import AirConditioner from './airConditioner.vue'
import AirConditioner4 from './airConditioner4.vue'
import AirConditioner43 from './airConditioner43.vue'
import AirConditioner49 from './airConditioner49.vue'

function airConditionerGenertor(h,devSecondType,jdlDeviceId){
    const strategies = {
       4:<AirConditioner4 deviceId={ jdlDeviceId }/>,
       43:<AirConditioner43 deviceId={ jdlDeviceId }/>,
       49:<AirConditioner49 deviceId={ jdlDeviceId }/>
    }
    if(strategies[devSecondType]){
        return strategies[devSecondType]
    }
    return <AirConditioner deviceId={ jdlDeviceId }/>
}

export default airConditionerGenertor