<template>
  <div v-loading="loading">
    <el-row>
      <el-col :span="8">
        <div class="info__box">
          <div class="info__center">
            <div v-if="info.temperature" class="info__temperature">
              {{ info.temperature }}℃
            </div>
            <div v-if="!info.temperature" class="el-upload__tip red">
              你可以试试配对,然后打开设备
            </div>
            <div>
              <el-button
                @click="temperatureControl('减')"
                icon="el-icon-minus"
                size="mini"
                type="primary"
              />
              <el-button
                @click="temperatureControl('加')"
                icon="el-icon-plus"
                size="mini"
                type="primary"
              />
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <slot name="left"></slot>
      </el-col>
      <el-col :span="8">
        <slot name="right"></slot>
      </el-col>
    </el-row>
    <div class="flex">
      <el-button style="width: 100px" type="primary" @click="getDeviceInfo"
        >搜索设备</el-button
      >
      <el-button style="width: 80px" type="primary" @click="clickBtn('开')"
        >开</el-button
      >
      <el-button style="width: 80px" type="primary" @click="clickBtn('关')"
        >关</el-button
      >
      <el-button type="primary" style="width: 80px" @click="openDialog('模式')"
        >模式</el-button
      >
      <el-button type="primary" style="width: 80px" @click="openDialog('风速')"
        >风速</el-button
      >
      <slot name="button"></slot>
      <el-button style="width: 80px" type="primary" @click="pair"
        >配对</el-button
      >
    </div>
    <el-dialog
      :visible.sync="innerVisible"
      :title="title"
      width="200px"
      append-to-body
    >
      <el-row
        style="margin: 8px 0 8px 0"
        v-for="(item, index) in buttonList[title]"
        :key="index"
      >
        <el-button
          @click="selectModeAndSpeed(item)"
          style="width: 160px"
          type="primary"
          >{{ item }}</el-button
        >
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { simpleOperationMixin} from "../mixin";
import { buttonList } from "./common";
import { getBatchState, operationDevice } from "../../api/device";
export default {
  props: {
    deviceId: Number,
    info: Object,
  },
  mixins: [simpleOperationMixin],
  data() {
    return {
      loading: false,
      innerVisible: false,
      title: null,
      form: {
        devId: this.deviceId,
        //   模式
        devAirPattern: "自动",
        //   风速
        devAirWindSpeed: "自动",
        devAirSweepingWind:'自动',
        //   温度
        devAirTemperature: 16,
        //
        devState: "开",
      },
      buttonList,
    };
  },
  methods: {
    getDeviceInfo() {
      this.loading = true
      getBatchState({ ids: this.deviceId })
        .then((res) => {
          if (!res.data.result[0].online) {
            this.$message({
              type: "warning",
              message: "设备不在线啊",
            });
          }
          this.loading = false;
          this.$emit("updateDeviceInfo", res.data.result[0]);
        })
        .catch((err) => {
          this.loading = false;
          this.isError(err, this);
        });
    },
    handleClose() {
      this.innerVisible = false;
    },
    openDialog(title) {
      this.innerVisible = true;
      this.title = title;
    },
    selectModeAndSpeed(item) {
      if (this.title === "模式") {
        this.form.devAirPattern = item;
      }
      if (this.title === "风速") {
        this.form.devAirWindSpeed = item;
      }
      if(this.title === "扫风"){
        this.form.devAirSweepingWind = item;
      }
      this.control();
    },
    temperatureControl(flag) {
      if (flag === "减" && this.form.devAirTemperature === 16) return;
      if (flag === "减") {
        this.form.devAirTemperature--;
      }
      if (flag === "加") {
        this.form.devAirTemperature++;
      }
      this.control()
    },
    control() {
      console.log('调用');
      this.loading = true;
      operationDevice(this.form)
        .then(async (res) => {
          this.getDeviceInfo();
        })
        .catch((err) => {
          this.isError(err, this);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.info__temperature {
  font-size: 42px;
  color: green;
}
.info__box {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info__center {
  text-align: center;
}
.flex {
  display: flex;
}
.red {
  color: red;
}
</style>