<template>
    <div>
        <div class="flex">
            <el-button type="primary" @click="clickBtn('开')">开</el-button>
            <el-button type="primary" @click="clickBtn('关')">关</el-button>
            <el-button type="primary" @click="clickBtn('暂停')">暂停</el-button>
            <slot name="button"/>
        </div>
        <div>
            <slot name="slider"/>
        </div>
    </div>
</template>

<script>
import { simpleOperationMixin } from '../mixin'
export default {
    props:{
        deviceId:Number
    },
    mixins:[simpleOperationMixin]
}
</script>

<style scoped>
.flex{
    display: flex;
}
</style>