export const buttonList = [
    {
       key:1,
       name:'开灯'
    },
    {
        key:2,
        name:'关灯'
     },
     {
        key:3,
        name:'停止升降'
     },
     {
        key:4,
        name:'烘干开'
     },
     {
        key:5,
        name:'烘干关'
     },
     {
        key:6,
        name:'风干开'
     },
     {
        key:7,
        name:'风干关'
     },
     {
        key:8,
        name:'消毒开'
     },
     {
        key:9,
        name:'消毒关'
     },
     {
        key:10,
        name:'升晾衣架',
        flag:'上升'
     },
     {
        key:11,
        name:'降晾衣架',
        flag:'下降'
     }
]