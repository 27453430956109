<template>
    <div>
        <AirConditioner ref="airConditioner" :info="deviceInfo" :deviceId="deviceId">
            <div slot="left">
                <div class="info__style" v-for="item in infoLeft" :key="item.key">
                {{ item.text }}:{{ deviceInfo[item.key] }}
                </div>
            </div>
            <div slot="right">
                <div class="info__style" v-for="item in infoRight" :key="item.key">
                {{ item.text }}:{{ deviceInfo[item.key] }}
                </div>
            </div>
            <el-button slot="button" type="primary" @click="sweeping">扫风</el-button>
        </AirConditioner>
    </div>
</template>

<script>
import AirConditioner from './airConditioner.vue'
import { infoLeft,infoRight } from './common'
export default {
    components:{
        AirConditioner
    },
    props:{
        deviceId:Number
    },
    data(){
        return {
            deviceInfo:{},
            infoLeft,
            infoRight
        }
    },
    methods:{
        sweeping(){
            this.$refs['airConditioner'].openDialog("扫风")
        }
    }
}

</script>

<style scoped>
.info__style {
  color: green;
  font-size: 14px;
  margin: 20px 0 20px 0;
}
</style>